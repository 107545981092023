import styled from "styled-components"


export const Container = styled.div`
display: flex;
flex-wrap: wrap;
padding: 40px;
width: 100%;
gap: 40px;
color: white;
`

export const Column = styled.div`
display: flex;
flex-direction: column;
width: calc(50% - 20px);
@media (max-width: 1000px) {
    width: 100%;
}
gap: 40px;
`

export const Row = styled.div`
display: flex;
flex-direction: row;
width: 100%;
gap: 40px;
`