// Style
import { Container, FormIcon, FormItem, Input, Select } from "./FormStyle"

// Helpers

// Formik
import createUser from "@common/api/user/createUser"
import getTeamLead from "@common/api/user/getTeamLead"
import Colors from "@common/Colors"
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import Icon from "@common/components/Icon"
import { partnerCreateValidation } from "@helpers/validations"
import { clearDrawerSlice } from "@redux/slices/drawerSlice"
import { notification } from "antd"
import { useFormik } from "formik"
import { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

const { Option } = Select


export default function Form() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [managersArray, setManagersArray] = useState([])

    const searchRef = useRef()

    const handleEdit = useFormik({
        initialValues: {
            FullName: "",
            Email: "",
            Password: "",
            Parent: null,
            AccountType: 2,
            ParentId: null,
            ParentToken: null,
        },
        validationSchema: partnerCreateValidation,
        onSubmit: async (values) => {
            try {
                setIsLoading(true)
                var firstName = values?.FullName.split(' ').slice(0, -1).join(' ')
                var lastName = values?.FullName.split(' ').slice(-1).join(' ')

                values.FirstName = firstName
                values.LastName = lastName
                values.ParentId = JSON.parse(values.Parent)?.Id
                values.ParentToken = JSON.parse(values.Parent)?.TrackToken

                delete values.Parent
                delete values.FullName

                const response = await createUser({ requestBody: values })

                if (response) {
                    searchParams.set("refresh", Math.floor(Math.random() * 100))
                    navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                    dispatch(clearDrawerSlice())
                    notification.success({
                        message: 'Başarılı',
                        description:
                            'Partner başarıyla oluşturuldu.',
                    })
                } else {
                    setIsLoading(false)
                    notification.error({
                        message: 'Hata',
                        description:
                            'Üzgünüz bir hatayla karşılaştık. Lütfen sistem admini ile iletişime geçin yaz.',
                    })
                }
            } catch (err) {
                console.log(err)
            }
        },
    })


    const handleOnTagUsersSearch = (e) => {
        clearTimeout(searchRef.current)
        searchRef.current = setTimeout(async () => {
            const response = await getTeamLead({ text: e, take: 5 })
            if (response?.responseJson) {
                setManagersArray(response?.responseJson)
            }
        }, 300)
    }

    return (
        <Container onSubmit={handleEdit.handleSubmit}>
            <FormItem>
                <FormIcon>
                    <Icon
                        name="user-outline"
                        width="20"
                        height="20"
                        color={
                            handleEdit.values.FullName?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="FullName"
                    placeholder="İsim Soyisim"
                    onChange={handleEdit.handleChange}
                    value={handleEdit.values.FullName}
                    errors={((handleEdit.errors.FullName && handleEdit.touched.FullName) ?? "").toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="lock-outline"
                        width="20"
                        height="20"
                        color={
                            handleEdit.values.Password?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="password"
                    name="Password"
                    placeholder="Şifre"
                    onChange={handleEdit.handleChange}
                    value={handleEdit.values.Password}
                    errors={((handleEdit.errors.Password && handleEdit.touched.Password) ?? "").toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="email-outline"
                        width="20"
                        height="20"
                        color={
                            handleEdit.values.Email?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="Email"
                    placeholder="E-posta Adresi"
                    onChange={handleEdit.handleChange}
                    value={handleEdit.values.Email}
                    errors={((handleEdit.errors.Email && handleEdit.touched.Email) ?? "").toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="user-outline"
                        width="20"
                        height="20"
                        color={
                            handleEdit.values.ParentId !== null
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>

                <Select
                    placeholder={"Yönetici Ara"}
                    onChange={(val) => handleEdit.setFieldValue("Parent", val)}
                    value={handleEdit.values.Parent && JSON.parse(handleEdit.values.Parent)?.FirstName + " " + JSON.parse(handleEdit.values.Parent)?.LastName}
                    showSearch
                    filterOption={false}
                    onSearch={(e) => handleOnTagUsersSearch(e)}
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                >
                    {
                        managersArray?.map((manager, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={JSON.stringify(manager)}
                                >
                                    {manager?.FirstName + " " + manager?.LastName}
                                </Option>
                            )
                        })
                    }
                </Select>
            </FormItem>

            <PrimaryButton onClick={handleEdit.handleSubmit} name="Kaydet" icon="plus-outline" loading={isLoading} />
        </Container>
    )
}
