import Colors from "@common/Colors"
import Icon from "@common/components/Icon"
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { SecondaryButton } from "../buttons/SecondaryButton/SecondaryButton"
import {
    Bottom, ButtonList, Container, Form, FormIcon, FormItem, Input,
    Menu,
    MenuListe,
    MenuListeItem,
    Select, Stats, StatsName, StatsValue, Title, Top
} from "./HeaderStyle"
const { RangePicker } = DatePicker
dayjs.extend(customParseFormat)

const { Option } = Select

export const Header = ({ title, icon, newButtonTitle, newButtonEvent, stats, search, datePicker, dropdown, menu, selectedUserId, hasBackButton, handleBackButton }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [searchParams] = useSearchParams()
    const searchTimer = useRef(null)
    const [searchValue, setSearchValue] = useState("")
    const [dropdownValue, setDropdownValue] = useState([])

    useEffect(() => {
        clearTimeout(searchTimer.current)
        searchTimer.current = setTimeout(() => {
            const text = searchParams.get("search")
            if (((!text && searchValue !== "") || searchValue || (text && searchValue === "")) && text !== searchValue) {
                searchParams.set("search", searchValue)
                searchParams.set("page", 1)
                if (selectedUserId) {
                    searchParams.set("user", selectedUserId)
                }
                navigate({ pathname: location.pathname, search: `?${searchParams.toString()}` })
            }
        }, 1000)
    }, [searchValue])// eslint-disable-line

    useEffect(() => {
        const user = searchParams.get("user")
        if (((!user && selectedUserId) || selectedUserId || (user && !selectedUserId)) && user !== selectedUserId) {
            searchParams.set("page", 1)
            searchParams.set("user", selectedUserId)
            navigate({ pathname: location.pathname, search: `?${searchParams.toString()}` })
        }
    }, [selectedUserId])// eslint-disable-line

    useEffect(() => {
        if (search && searchParams.get("search")) {
            setSearchValue(searchParams.get("search"))
        }

        if (dropdown?.length > 0) {
            dropdown?.forEach((item, index) => {
                if (searchParams.get(item?.searchParam)) {
                    setDropdownValue((old) => [
                        ...old,
                        {
                            value: searchParams.get(item?.searchParam),
                            index: index
                        }
                    ])
                }
            })

        }
    }, [])// eslint-disable-line

    useEffect(() => {
        dropdownValue?.forEach((item, index) => {
            const value = searchParams.get(dropdown[index]?.searchParam)
            if (((!value && item?.value !== undefined) || item?.value) && value !== item?.value) {
                searchParams.set(dropdown[item?.index]?.searchParam, item?.value)
                searchParams.set("page", 1)
                if (selectedUserId) {
                    searchParams.set("user", selectedUserId)
                }
                navigate({ pathname: location.pathname, search: `?${searchParams.toString()}` })
            }
        })
    }, [dropdownValue])// eslint-disable-line

    useEffect(() => {
        dropdown?.forEach((item, index) => {
            setDropdownValue((old) => [
                ...old, {
                    value: null,
                    index: index
                }])
        })
    }, [dropdown])// eslint-disable-line

    const onDropdownValueChange = (val, index) => {
        const copyOfDropdownValue = JSON.parse(JSON.stringify(dropdownValue))
        const copyIndex = copyOfDropdownValue.findIndex((v) => v.index === index)
        if (copyIndex > -1) {
            copyOfDropdownValue[index].value = val
            setDropdownValue(copyOfDropdownValue)
        } else {
            setDropdownValue((old) => [
                ...old, {
                    value: val,
                    index: index
                }])
        }

    }

    const onChangeDate = (value, dateString) => {
        searchParams.set("start", dateString[0] ? dayjs(dateString[0], "DD-MM-YY").format("YYYY-MM-DD") : "")
        searchParams.set("end", dateString[1] ? dayjs(dateString[1], "DD-MM-YY").format("YYYY-MM-DD") : "")
        searchParams.set("page", 1)
        navigate({ pathname: location.pathname, search: `?${searchParams.toString()}` })
    }

    const handleMenuItem = (value) => {
        searchParams.set("page", 1)
        navigate({ pathname: value, search: `?${searchParams.toString()}` })
    }


    return (
        <Container>
            <Top>
                <div onClick={() => hasBackButton && handleBackButton()}>
                    <Icon
                        name={icon}
                        width={hasBackButton ? "24" : "32"}
                        height={hasBackButton ? "24" : "32"}
                        color={Colors.white}
                    />
                </div>
                <Title>
                    {title}
                </Title>
                <ButtonList>
                    {newButtonTitle && <SecondaryButton onClick={newButtonEvent} name={newButtonTitle} icon="plus-circle-outline" />}
                </ButtonList>
            </Top>
            {
                (stats || datePicker || search || dropdown) &&
                <Bottom>
                    {
                        stats?.map((item, index) =>
                            <Stats key={index}>
                                <StatsValue>{item.value}</StatsValue>
                                <StatsName>{item.name}</StatsName>
                            </Stats>
                        )
                    }
                    <Form>
                        {
                            datePicker &&
                            <FormItem>
                                <FormIcon>
                                    <Icon
                                        name="calendar-outline"
                                        width="20"
                                        height="20"
                                        color={
                                            searchValue?.length > 0
                                                ? Colors.white
                                                : Colors.white400
                                        }
                                    />
                                </FormIcon>
                                <RangePicker
                                    format={'DD/MM/YY'}
                                    name="date"
                                    placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                                    separator={<p>-</p>}
                                    suffixIcon={<Icon
                                        name="nav-down-outline"
                                        width="20"
                                        height="20"
                                        color={
                                            searchValue?.length > 0
                                                ? Colors.white
                                                : Colors.white400
                                        }
                                    />}
                                    clearIcon={<Icon
                                        name="x-outline"
                                        width="20"
                                        height="20"
                                        color={
                                            searchValue?.length > 0
                                                ? Colors.white
                                                : Colors.white400
                                        }
                                    />}
                                    disabledDate={(current) => {
                                        let customDate = dayjs().format("DD/MM/YY")
                                        return current && current > dayjs(customDate, "DD/MM/YY")
                                    }}
                                    defaultValue={(searchParams.get("start") && searchParams.get("end")) ?
                                        [
                                            dayjs(dayjs(searchParams.get("start"), 'YYYY-MM-DD'), "DD/MM/YY"),
                                            dayjs(dayjs(searchParams.get("end"), 'YYYY-MM-DD'), "DD/MM/YY"),
                                        ] : []}
                                    onChange={onChangeDate}
                                />
                            </FormItem>
                        }
                        {
                            dropdown?.length > 0 &&
                            dropdown?.map((item, index) => {

                                return (
                                    <FormItem key={"dropdown" + index}>
                                        {
                                            item?.icon &&
                                            <FormIcon>
                                                <Icon
                                                    name={item?.icon}
                                                    width="20"
                                                    height="20"
                                                    color={
                                                        searchValue?.length > 0
                                                            ? Colors.white
                                                            : Colors.white400
                                                    }
                                                />
                                            </FormIcon>
                                        }

                                        <Select
                                            placeholder={item?.placeholder}
                                            mode={item?.mode}
                                            placement={"bottomRight"}
                                            onChange={(val) => onDropdownValueChange(val, index)}
                                            value={dropdownValue?.[index]?.value}
                                            filterOption={false}
                                            hasicon={item?.icon}
                                            suffixIcon={
                                                <Icon
                                                    name="nav-down-outline"
                                                    width="20"
                                                    height="20"
                                                    color={Colors.white400}
                                                />
                                            }
                                        >
                                            {
                                                item?.data?.map((option, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            value={option[item.optionValueKey]}
                                                        >
                                                            {option[item.optionTextKey]}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormItem>
                                )
                            })
                        }
                        {
                            search &&
                            <FormItem>
                                <FormIcon>
                                    <Icon
                                        name="search-outline"
                                        width="20"
                                        height="20"
                                        color={
                                            searchValue?.length > 0
                                                ? Colors.white
                                                : Colors.white400
                                        }
                                    />
                                </FormIcon>
                                <Input
                                    type="text"
                                    name="search"
                                    placeholder="Ara"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    defaultValue={searchValue}
                                />
                            </FormItem>
                        }
                    </Form>
                </Bottom>
            }
            {
                menu &&
                <Menu>
                    <MenuListe>
                        {
                            menu.map((item, index) =>
                                <MenuListeItem
                                    onClick={() => handleMenuItem(item?.value)}
                                    key={index}
                                    selected={(location.pathname.split('/')[2] ? location.pathname.split('/')[2] : ".") === item.value}
                                >
                                    {item?.name}
                                </MenuListeItem>
                            )
                        }

                    </MenuListe>
                </Menu>
            }
        </Container>
    )
}
