
import styled from "styled-components"

import Colors from "@common/Colors"
import { DashboardBox } from "@common/style"

export const Container = styled(DashboardBox)`
width: fit-content;
`

export const Text = styled.div`
color: white;
`

export const Title = styled(Text)`
margin-bottom: 24px;
font-size: 18px;
line-height: 24px;
font-weight: 600;
`

export const Description = styled(Text)`
font-size: 13px;
line-height: 20px;
font-weight: 500;
color: ${Colors.white400};
`

export const Link = styled(Description)`
margin-bottom: 12px;
color: white;
`


export const Row = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap:12px;
`