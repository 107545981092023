import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

// Redux
import { setCloseDisable } from '@redux/slices/drawerSlice'
import store from '@redux/store'

export default async function updateVoice({ requestBody }) {

    store.dispatch(setCloseDisable(true))

    const response = await apiCall({
        config: API.VOICE.UPDATE,
        requestBody: requestBody,
    })

    if (response) {
        store.dispatch(setCloseDisable(false))
        return response
    } else {
        store.dispatch(setCloseDisable(false))
        return null
    }

}
