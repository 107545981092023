import { useState } from "react"

// Style
import { Container, EmptyImage, FormIcon, FormItem, FormRadio, Image, ImageBadge, ImageContainer, Input } from "./FormStyle"

// Helpers

// Color
import Colors from "@common/Colors"

// Icon
import Icon from "@common/components/Icon"

// Formik
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import { clearDrawerSlice } from "@redux/slices/drawerSlice"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { notification } from "antd"
import uploadVoiceCategory from "@common/api/voiceCategory/uploadVoiceCategory"

export default function Form() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const [image, setImage] = useState(null)
    const [imageToShow, setImageToShow] = useState(null)
    const [title, setTitle] = useState("")
    const [language, setLanguage] = useState("TR")


    const [submitClicked, setSubmitClicked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    const handleOnImageSelectClick = () => {
        document.getElementById('image').click()
    }

    const onImageSelect = event => {
        setImage(event.target.files[0])

        if (event.target.files && event.target.files[0]) {
            setImageToShow(URL.createObjectURL(event.target.files[0]))
        }
    }

    const onLanguageChange = e => {
        setLanguage(e.target.value)
    }


    const handleOnSubmit = async () => {
        setSubmitClicked(true)

        if (title?.length > 0) {
            setIsLoading(true)
            const response = await uploadVoiceCategory({
                image: image?.name ? image : null,
                title: title,
                language: language
            })

            if (response) {
                searchParams.set("refresh", Math.floor(Math.random() * 100))
                navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                dispatch(clearDrawerSlice())
                notification.success({
                    message: 'Başarılı',
                    description:
                        'Vomoji seti başarıyla yüklendi.',
                });
            } else {
                setIsLoading(false)
                notification.error({
                    message: 'Hata',
                    description:
                        'Vomoji seti yüklenirken hata oluştu.',
                });
            }
        }
    }



    return (
        <Container>
            <ImageContainer>
                {
                    imageToShow ?
                        <Image src={imageToShow} />
                        :
                        <EmptyImage errors={false} />
                }

                <ImageBadge onClick={() => handleOnImageSelectClick()}>
                    <Icon
                        name="plus-circle-outline"
                        width="20"
                        height="20"
                        color={Colors.background}
                    />
                </ImageBadge>
            </ImageContainer>

            <FormRadio>
                <div>
                    <input
                        type="radio"
                        name="topping"
                        value="TR"
                        id="tr"
                        checked={language === "TR"}
                        onChange={onLanguageChange}
                    />
                    <label htmlFor="tr">Türkçe</label>
                </div>
                <div>
                    <input
                        type="radio"
                        name="language"
                        value="EN"
                        id="en"
                        checked={language === "EN"}
                        onChange={onLanguageChange}
                    />
                    <label htmlFor="en">İngilizce</label>
                </div>
            </FormRadio>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            title?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="duration"
                    placeholder={`Vomoji Set Adı (${language})`}
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    value={title}
                    errors={((!title || title === "") && submitClicked).toString()}
                />
            </FormItem>


            <PrimaryButton type={"button"} onClick={() => handleOnSubmit()} name="Kaydet" icon="plus-outline" loading={isLoading} />

            <input id="image" style={{ visibility: "hidden" }} type="file" accept="image/*" onChange={onImageSelect} />
        </Container>
    )
}
