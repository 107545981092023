import styled from "styled-components"

import {
  Date,
  FormEmptyImage,
  FormFile,
  FormImage,
  FormImageBadge,
  FormImageItem,
  FormInput,
  FormInputBig,
  FormInputIcon,
  FormInputItem,
  FormInputSuffixIcon,
  FormSelect,
  FormSwitch,
  FormSwitchItem,
  FormSwitchText
} from "@common/style"

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 40px;
`

export const ImageContainer = styled(FormImageItem)``
export const Image = styled(FormImage)``
export const EmptyImage = styled(FormEmptyImage)``
export const ImageBadge = styled(FormImageBadge)``

export const FormItem = styled(FormInputItem)``
export const FormIcon = styled(FormInputIcon)``
export const FormTopIcon = styled(FormInputIcon)`
  top: 10px;
`
export const FormSuffixIcon = styled(FormInputSuffixIcon)``

export const Input = styled(FormInput)`
  height: 56px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
`

export const BigInput = styled(FormInputBig)``

export const File = styled(FormFile)``

export const DateContainer = styled(Date)``

export const SwitchItem = styled(FormSwitchItem)``

export const SwitchText = styled(FormSwitchText)``

export const Switch = styled(FormSwitch)``

export const Select = styled(FormSelect)``
