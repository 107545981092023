import { Container } from "./LoadingStyle"
import Logo from '@assets/poddy-200.gif'

export const Loading = () => {
    return (
        <Container>
            <img src={Logo} width={80} height={80} alt="poddy-gif" />
        </Container>
    )
}
