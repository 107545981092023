import apiCall from "../apiCall"
import { API } from "../Config"


export default async function getPodcastById({
    id
}) {

    const parameter = `?podcastId=${id}`

    const response = await apiCall({
        config: API.PODCAST.GET_BY_ID,
        parameters: parameter
    })

    if (response) {
        return response
    } else {
        return null
    }

}
