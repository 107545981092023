import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function getUsers({ text = "", skip = 0, take = 15, startDate = "", endDate = "" }) {

    const parameters = `?text=${text ?? ""}&skip=${skip ?? 0}&take=${take ?? 15}&startDate=${startDate ?? ""}&endDate=${endDate ?? ""}`

    const response = await apiCall({
        config: API.USER.GET_USERS,
        parameters: parameters,
    })

    if (response) {
        return response
    } else {
        return null
    }

}
