import Form from "../Form/Form"
import Header from "../Header/Header"
import { Container } from "./EditContentStyle"

export default function EditContent({
    podcastData,
    setIsPodcastEdit,
    setPodcastToEdit
}) {

    return (
        <Container>
            <Header
                setIsPodcastEdit={setIsPodcastEdit}
                setPodcastToEdit={setPodcastToEdit}
            />

            <Form
                podcastData={podcastData}
            />
        </Container>
    )
}
