
// Api

// Components

import { Container, Count, Description, Title } from './TotalGainedUsersStyle'

export default function TotalGainedUsers({ dashboardData }) {



    return (
        <Container>
            <Title>
                Toplam Kazanılan Kullanıcı
            </Title>

            <Description>
                Sizin ve Ekibinizin kazandırmış olduğu toplam kullanıcı sayısını ifade eder.
            </Description>

            <Count>
                {dashboardData?.TotalTrackedUserCount}
            </Count>
        </Container>
    )
}
