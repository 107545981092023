import Colors from "@common/Colors"
import styled from "styled-components"

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0px 40px;
margin-top: 40px;
`

export const Image = styled.img`
width: 128px;
height: 128px;
border-radius: 12px;
`

export const Title = styled.div`
margin-top: 32px;
font-size: 20px;
font-weight: 600;
font-family: "Plus Jakarta Sans";
color: ${Colors.white};
`

export const Username = styled.div`
margin-top: 5px;
font-size: 14px;
font-weight: 500;
font-family: "Plus Jakarta Sans";
color: ${Colors.white600};
`

export const Description = styled(Username)`
margin-top: 25px;
`