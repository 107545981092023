import Logo from '@assets/poddy-200.gif'
import Colors from "@common/Colors"
import Icon from "@common/components/Icon"
import { Button } from "./SecondaryButtonStyle"

export const SecondaryButton = ({ name, icon, onClick, fullWidth = false, isDisable, loading }) => {
    return (
        <Button
            onClick={() => !isDisable && onClick()}
            fullWidth={fullWidth}
            isDisable={isDisable}
            disabled={loading}
        >
            {
                loading ?
                    <img src={Logo} width={20} height={20} alt="poddy-gif" />
                    :
                    <Icon
                        name={icon}
                        width="20"
                        height="20"
                        color={Colors.white}
                    />
            }

            {name}
        </Button>
    )
}
