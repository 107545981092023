import styled from "styled-components"

import { Date, FormInput, FormInputIcon, FormInputItem, FormSelect, FormSwitch, FormSwitchItem, FormSwitchText } from "@common/style"

export const Container = styled.form`
  padding: 40px;
`

export const FormItem = styled(FormInputItem)``
export const FormIcon = styled(FormInputIcon)``

export const Input = styled(FormInput)`
  height: 56px;
`

export const DateContainer = styled(Date)``


export const SwitchItem = styled(FormSwitchItem)``

export const SwitchText = styled(FormSwitchText)``

export const Switch = styled(FormSwitch)``

export const Select = styled(FormSelect)``

