import CreateContent from "./CreateContent/CreateContent"

export default function VomojiItemCreateDrawer({
    propsData,
    setIsItemCreate
}) {
    return (
        <CreateContent
            propsData={propsData}
            setIsItemCreate={setIsItemCreate}
        />
    )
}
