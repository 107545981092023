import getShowById from "@common/api/show/getShowById"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import DetailContent from "./detail/DetailContent/DetailContent"
import EditContent from "./edit/EditContent/EditContent"
import PodcastCreateDrawer from "./podcast/PodcastCreateDrawer/PodcastCreateDrawer"
import PodcastEditDrawer from "./podcast/PodcastEditDrawer/PodcastEditDrawer"

export default function ShowDetailDrawer() {

    const drawerProps = useSelector(state => state.drawer.drawerProps)

    const [isEdit, setIsEdit] = useState(false)
    const [isPodcastEdit, setIsPodcastEdit] = useState(false)
    const [podcastToEdit, setPodcastToEdit] = useState(null)
    const [isPodcastCreate, setIsPodcastCreate] = useState(false)
    const [showData, setShowData] = useState(null)
    const [showEpisodes, setShowEpisodes] = useState(null)

    useEffect(() => {
        if (drawerProps?.showId) {
            const getData = async () => {
                const response = await getShowById({ showId: drawerProps?.showId })

                if (response?.responseJson) {
                    setShowData(response?.responseJson)
                    setShowEpisodes(response?.responseJson?.Episodes)
                }
            }

            getData()
        }
    }, [drawerProps])

    return (
        isEdit ?
            <EditContent
                setIsEdit={setIsEdit}
                showData={showData}
            />
            :
            isPodcastCreate ?
                <PodcastCreateDrawer
                    showData={showData}
                    setIsPodcastCreate={setIsPodcastCreate}
                />
                :
                isPodcastEdit ?
                    <PodcastEditDrawer
                        podcastToEdit={podcastToEdit}
                        setIsPodcastEdit={setIsPodcastEdit}
                        setPodcastToEdit={setPodcastToEdit}
                    />
                    :
                    <DetailContent
                        setIsEdit={setIsEdit}
                        setIsPodcastEdit={setIsPodcastEdit}
                        setPodcastToEdit={setPodcastToEdit}
                        setIsPodcastCreate={setIsPodcastCreate}
                        showData={showData}
                        showEpisodes={showEpisodes}
                        setShowEpisodes={setShowEpisodes}
                    />
    )
}
