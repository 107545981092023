import { useEffect, useState } from "react"

// Style
import { Container, FormIcon, FormItem, Input } from "./FormStyle"

// Color
import Colors from "@common/Colors"

// Icon
import Icon from "@common/components/Icon"

// Formik
import updateVoice from "@common/api/voice/updateVoice"
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import { clearDrawerSlice } from "@redux/slices/drawerSlice"
import { notification } from "antd"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

export default function Form({
    isItemEditData
}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const [title, setTitle] = useState("")
    const [titleEN, setTitleEN] = useState("")

    const [submitClicked, setSubmitClicked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (isItemEditData) {

            const index = isItemEditData?.TitleLanguageDetail?.findIndex(item => item.Language === "TR")
            const indexEN = isItemEditData?.TitleLanguageDetail?.findIndex(item => item.Language === "EN")

            setTitle(isItemEditData?.TitleLanguageDetail[index].Text)
            setTitleEN(isItemEditData?.TitleLanguageDetail[indexEN].Text)
        }
    }, [isItemEditData])



    const handleOnSubmit = async () => {

        setSubmitClicked(true)

        if (title && titleEN) {

            const index = isItemEditData?.TitleLanguageDetail?.findIndex(item => item.Language === "TR")
            const indexEN = isItemEditData?.TitleLanguageDetail?.findIndex(item => item.Language === "EN")

            let newObject = isItemEditData?.TitleLanguageDetail
            newObject[index].Text = title
            newObject[indexEN].Text = titleEN

            const response = await updateVoice({
                requestBody: {
                    LanguageDetail: newObject
                }
            })

            if (response) {
                searchParams.set("refresh", Math.floor(Math.random() * 100))
                navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                dispatch(clearDrawerSlice())
                notification.success({
                    message: 'Başarılı',
                    description:
                        'Vomoji başarıyla güncellendi.',
                })
            } else {
                setIsLoading(false)
                notification.error({
                    message: 'Hata',
                    description:
                        'Vomoji güncellenirken hata oluştu.',
                })
            }
        }
    }

    return (
        <Container>
            <FormItem>
                <FormIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            title?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="title"
                    placeholder="Vomoji Adı (TR)"
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    value={title}
                    errors={((!title || title === "") && submitClicked).toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            titleEN?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="titleEN"
                    placeholder="Vomoji Adı (EN)"
                    onChange={(e) => setTitleEN(e.currentTarget.value)}
                    value={titleEN}
                    errors={((!titleEN || titleEN === "") && submitClicked).toString()}
                />
            </FormItem>

            <PrimaryButton type={"button"} onClick={() => handleOnSubmit()} name="Kaydet" icon="plus-outline" loading={isLoading} />
        </Container>
    )
}
