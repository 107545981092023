import * as Yup from "yup"

export const loginValidation = Yup.object({
    email: Yup.string().email("Geçersiz e-mail adresi").required("Zorunlu alan"),
    password: Yup.string().required("Zorunlu alan"),
})

export const userEditValidation = Yup.object({
    FullName: Yup.string().required(),
    UserName: Yup.string().required(),
    DateOfBirth: Yup.date(),
    CountryCode: Yup.number(),
    PhoneNumber: Yup.number(),
    Email: Yup.string().email(),
    Gender: Yup.number(),
    Biography: Yup.string(),
    IsVerified: Yup.boolean(),
})

export const userReachAccountValidation = Yup.object({
    CountryCode: Yup.number(),
    PhoneNumber: Yup.number(),
    Email: Yup.string().email(),
})

export const partnerCreateValidation = Yup.object({
    FullName: Yup.string().required(),
    Email: Yup.string().email().required(),
    Password: Yup.string().required().min(6),
    Parent: Yup.string().nullable(true),
});

