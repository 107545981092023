import { useEffect, useState } from 'react'

// Api

// Components
import getUser from '@common/api/user/getUser'
import { PrimaryButton } from '@common/components/buttons/PrimaryButton/PrimaryButton'
import { QRCode } from 'antd'
import { Container, Description, Link, Row, Title } from './InvitationLinkStyle'

export default function InvitationLink() {

    const [data, setData] = useState([])

    useEffect(() => {
        const getData = async () => {
            const response = await getUser()

            if (response?.responseJson) {
                setData(response?.responseJson)
            }
        }

        getData()
    }, [])

    const handleOnDownload = () => {
        const canvas = document.getElementById('qrCode')?.getElementsByTagName('canvas')?.[0]
        if (canvas) {
            const url = canvas.toDataURL()
            const a = document.createElement('a')
            a.download = 'QRCode.png'
            a.href = url
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        }
    }


    return (
        <Container>
            <Title>
                Davet Bağlantısı
            </Title>

            <Row id={"qrCode"}>
                <QRCode
                    bgColor='#FFF'
                    errorLevel="H"
                    value={data?.Url}
                />

                <div>
                    <Description>
                        Kısa Adresi
                    </Description>

                    <Link>
                        {data?.Url}
                    </Link>

                    <PrimaryButton type={"button"} onClick={() => handleOnDownload()} name="İndir" fullWidth={false} />
                </div>
            </Row>
        </Container>
    )
}
