const Colors = {
    // primaryGradient: "linear-gradient(90deg, #FD6C61 -1%, #FD5561 8.6%, #FD3662 25.66%, #FD1F63 42.72%, #FD0E64 60.85%, #FD0464 81.11%, #FD0165 105.63%)",
    primaryGradient: "#ff715b",
    black: "#070213",
    background: "#2d4159",
    white: "rgba(255, 255, 255, 1)",
    white100: "rgba(255, 255, 255, 0.1)",
    white200: "rgba(255, 255, 255, 0.2)",
    white300: "rgba(255, 255, 255, 0.3)",
    white400: "rgba(255, 255, 255, 0.4)",
    white500: "rgba(255, 255, 255, 0.5)",
    white600: "rgba(255, 255, 255, 0.6)",
    primary: "#ff715b",
    primary600: "rgba(255, 113, 91,0.6)",
    secondary: "#EC7567"
}

export default Colors