import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

// Redux
import { setCloseDisable } from '@redux/slices/drawerSlice'
import store from '@redux/store'

export default async function putUpdatePodcast({
    id,
    userId,
    image,
    sound,
    duration,
    title,
    showId,
    description,
    isCommentable,
    isPartnership,
    categories,
    userIds,
}) {

    store.dispatch(setCloseDisable(true))

    // Create an object of formData
    const formData = new FormData()

    // Update the formData object
    formData.append(
        "image",
        image,
        image.name
    )

    formData.append(
        'podcast',
        sound,
        sound.name
    )

    formData.append(
        'jsonString',
        JSON.stringify({
            Id: id,
            UserId: userId,
            Title: title,
            Description: description,
            Duration: duration,
            ShowId: showId,
            IsCommentable: isCommentable,
            IsPartnership: isPartnership,
            Categories: categories,
            TaggedUserIds: userIds,
        })
    )

    const response = await apiCall({
        config: API.PODCAST.UPDATE,
        requestBody: formData,
        contentType: 'multipart/form-data'
    })


    if (response) {
        store.dispatch(setCloseDisable(false))
        return response
    } else {
        store.dispatch(setCloseDisable(false))
        return null
    }

}
