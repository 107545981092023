import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

// Redux
import { setCloseDisable } from '@redux/slices/drawerSlice'
import store from '@redux/store'

export default async function putUpdatePodcaps({
    id,
    image,
    video,
    userId,
    duration,
    title,
    isCommentable,
    isPartnership,
    isAd,
    link,
    linkText,
    podcastId,
    categories,
    language,
    userIds,
    tag,
}) {

    store.dispatch(setCloseDisable(true))

    // Create an object of formData
    const formData = new FormData()

    // Update the formData object
    formData.append(
        "image",
        image,
        image.name
    )

    formData.append(
        'podcaps',
        video,
        video.name
    )

    formData.append(
        'jsonString',
        JSON.stringify({
            Id: id,
            UserId: userId,
            Title: title,
            Duration: duration,
            IsCommentable: isCommentable,
            IsPartnership: isPartnership,
            IsAd: isAd,
            PodcastId: podcastId,
            Link: link,
            LinkText: linkText,
            Categories: categories,
            TaggedUserIds: userIds,
            TagList: tag ? [tag] : null,
            CountryCode: language
        })
    )

    const response = await apiCall({
        config: API.PODCAPS.UPDATE,
        requestBody: formData,
        contentType: 'multipart/form-data'
    })

    if (response) {
        store.dispatch(setCloseDisable(false))
        return response
    } else {
        store.dispatch(setCloseDisable(false))
        return null
    }

}
