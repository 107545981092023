import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

// Redux
import { setCloseDisable } from '@redux/slices/drawerSlice'
import store from '@redux/store'

export default async function updateVoiceCategory({
    id,
    image,
    title,
    language,
}) {

    store.dispatch(setCloseDisable(true))

    // Create an object of formData
    const formData = new FormData()

    if (image) {
        formData.append(
            "image",
            image,
            image.name
        )
    }

    formData.append(
        'jsonString',
        JSON.stringify({
            Id: id,
            Type: 2,
            Text: title,
            VoiceCategoryDetail: [
                { Text: title, Language: language },
            ]
        })
    )

    const response = await apiCall({
        config: API.VOICE_CATEGORY.UPDATE,
        requestBody: formData,
        contentType: 'multipart/form-data'
    })


    if (response) {
        store.dispatch(setCloseDisable(false))
        return response
    } else {
        store.dispatch(setCloseDisable(false))
        return null
    }

}
