import EditContent from "./EditContent/EditContent"

export default function VomojiItemEditDrawer({
    setIsItemEdit,
    isItemEditData
}) {

    return (
        <EditContent
            setIsItemEdit={setIsItemEdit}
            isItemEditData={isItemEditData}
        />
    )
}
