
// Enums
import { DRAWER_CONTENT } from "@common/Enums"

// Redux
import { useSelector } from "react-redux"

// Components
import PartnerCreateDrawer from "@components/admin/partner/PartnerCreateDrawer/PartnerCreateDrawer"
import PodcapsCreateDrawer from "@components/admin/podcaps/PodcapsCreateDrawer/PodcapsCreateDrawer"
import PodcapsEditDrawer from "@components/admin/podcaps/PodcapsEditDrawer/PodcapsEditDrawer"
import RssCreateDrawer from "@components/admin/rss/RssCreateDrawer/RssCreateDrawer"
import VomojiCreateDrawer from "@components/admin/settings/vomoji/VomojiCreateDrawer/VomojiCreateDrawer"
import VomojiDetailDrawer from "@components/admin/settings/vomoji/VomojiDetailDrawer/VomojiDetailDrawer"
import ShowCreateDrawer from "@components/admin/show/ShowCreateDrawer/ShowCreateDrawer"
import ShowDetailDrawer from "@components/admin/show/ShowDetailDrawer/ShowDetailDrawer"
import VerificationDetailDrawer from "@components/admin/verification/VerificationDetailDrawer/VerificationDetailDrawer"

export default function CommonDrawerContent() {

    const drawerContent = useSelector(state => state.drawer.drawerContent)

    const content = () => {
        switch (drawerContent) {
            case DRAWER_CONTENT.PARTNER_CREATE:
                return (
                    <PartnerCreateDrawer />
                )
            case DRAWER_CONTENT.PODCAPS_CREATE:
                return (
                    <PodcapsCreateDrawer />
                )
            case DRAWER_CONTENT.PODCAPS_EDIT:
                return (
                    <PodcapsEditDrawer />
                )
            case DRAWER_CONTENT.SHOW_DETAIL:
                return (
                    <ShowDetailDrawer />
                )
            case DRAWER_CONTENT.SHOW_CREATE:
                return (
                    <ShowCreateDrawer />
                )
            case DRAWER_CONTENT.VOMOJI_DETAIL:
                return (
                    <VomojiDetailDrawer />
                )
            case DRAWER_CONTENT.VOMOJI_CREATE:
                return (
                    <VomojiCreateDrawer />
                )
            case DRAWER_CONTENT.RSS_CREATE:
                return (
                    <RssCreateDrawer />
                )
            case DRAWER_CONTENT.VERIFICATION_DETAIL:
                return (
                    <VerificationDetailDrawer />
                )
            default:
                return null
        }
    }

    return (
        content()
    )
}
