import { DashboardBox, Table, TableTitle, TableTitleImage, TableTitleText } from "@common/style"
import styled from "styled-components"

export const TableContainer = styled(Table)``
export const ShowTableTitle = styled(TableTitle)``
export const ShowTableTitleImage = styled(TableTitleImage)``
export const ShowTableTitleText = styled(TableTitleText)`
margin-left: 0px;
`

export const Container = styled(DashboardBox)`
/* height: stretch; */
`

export const Text = styled.div`
color: white;
`

export const Title = styled(Text)`
margin-bottom: 24px;
font-size: 18px;
line-height: 24px;
font-weight: 600;
`