export const API = {
    USER: {
        GET_TOKEN: {
            path: '/api/v1/user/get-token',
            method: 'GET',
        },
        CREATE_USER: {
            path: '/api/v1/user/create',
            method: 'POST',
        },
        GET: {
            path: '/api/v1/user/get',
            method: 'GET',
        },
        GET_TEAM: {
            path: '/api/v1/user/get-team',
            method: 'GET',
        },
        GET_TEAM_SEARCH_COUNT: {
            path: '/api/v1/user/get-team-search-count',
            method: 'GET',
        },
        GET_TEAM_LEAD: {
            path: '/api/v1/user/get-team-lead',
            method: 'GET',
        },
        GET_TEAM_LEAD_SEARCH_COUNT: {
            path: '/api/v1/user/get-team-lead-search-count',
            method: 'GET',
        },
        GET_TEAM_BY_ID: {
            path: '/api/v1/user/get-team-by-id',
            method: 'GET',
        },
    },
    DASHBOARD: {
        GET: {
            path: '/api/v1/dashboard/get',
            method: 'GET',
        },
        GET_BY_USER_ID: {
            path: '/api/v1/dashboard/get-by-user-id',
            method: 'GET',
        },
        GET_ALL: {
            path: '/api/v1/dashboard/get-all',
            method: 'GET',
        },
    }
}