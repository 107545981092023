// Style
import { Container } from "./AdminLayoutStyle"

// Components
import CommonDrawer from "@common/components/CommonDrawer/CommonDrawer"
import Menu from "@components/admin/Menu/Menu"
import { Outlet } from "react-router-dom"

export default function AdminLayout() {
    return (
        <Container>
            <Menu />
            <Outlet />

            <CommonDrawer />
        </Container>
    )
}
