import deletePodcast from '@common/api/podcast/deletePodcast'
import Colors from '@common/Colors'
import Icon from '@common/components/Icon'
import { clearDrawerSlice } from '@redux/slices/drawerSlice'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { Button, Container, Controls, Date, Image, Item, Row, Title } from './DetailStyle'
import { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { notification } from 'antd'

export default function Detail({
    setIsPodcastEdit,
    showEpisodes,
    setPodcastToEdit,
}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const [isLoadingDelete, setIsLoadingDelete] = useState(false)

    const getDateAndDuration = (date, duration) => {
        const convertedDuration = convertDuration(duration)
        const convertedDate = dayjs(date).format("DD MMMM YYYY")

        return `${convertedDate} - ${convertedDuration}`
    }

    const convertDuration = (value) => {
        const hour = value / 3600
        const minute = (value % 3600) / 60
        if (parseInt(hour) === 0) {
            return `${parseInt(minute)} dk`
        }
        else {
            return `${parseInt(hour)} s ${parseInt(minute)} dk`
        }
    }

    const handleOnEdit = (id) => {
        setIsPodcastEdit(true)
        setPodcastToEdit(id)
    }

    const handleOnDelete = async (id) => {
        if (!isLoadingDelete) {
            setIsLoadingDelete(true)
            const response = await deletePodcast({ id: id })

            if (response) {


                searchParams.set("refresh", Math.floor(Math.random() * 100))
                navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                dispatch(clearDrawerSlice())
                notification.success({
                    message: 'Başarılı',
                    description:
                        'Podcast başarıyla silindi.',
                });
                setIsLoadingDelete(false)

            } else {
                notification.error({
                    message: 'Hata',
                    description:
                        'Podcast silinirken hata oluştu.',
                });
                setIsLoadingDelete(false)
            }
        }

    }

    return (
        <Container>
            {
                showEpisodes?.map((podcast) => {
                    return (
                        <Item key={podcast?.Id}>
                            <Row>
                                <Image src={podcast?.ImageUrl} />

                                <Title>
                                    {podcast?.Title}
                                </Title>
                            </Row>
                            <Row>
                                <Date>
                                    {getDateAndDuration(podcast?.CreatedAt, podcast?.Duration)}
                                </Date>

                                <Controls>
                                    <Button onClick={() => handleOnEdit(podcast?.Id)}>
                                        <Icon
                                            name="pen-outline"
                                            width="20"
                                            height="20"
                                            color={Colors.white}
                                        />
                                    </Button>
                                    <Button onClick={() => handleOnDelete(podcast?.Id)} disable={isLoadingDelete}>
                                        <Icon
                                            name="trash-outline"
                                            width="20"
                                            height="20"
                                            color={Colors.white}
                                        />
                                    </Button>
                                </Controls>
                            </Row>
                        </Item>
                    )
                })
            }
        </Container>
    )
}
