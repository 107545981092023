import Colors from "@common/Colors"
import { Drawer } from "antd"
import styled from "styled-components"


export const DrawerContainer = styled(Drawer)`
background-color: ${Colors.background} !important;

.ant-drawer-header{
    display: none;
}

.ant-drawer-body{
    padding: 0;
    overflow: hidden !important;
}
` 