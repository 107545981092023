import { useEffect, useRef, useState } from "react"

// Style
import { BigInput, Container, EmptyImage, FileInput, FormIcon, FormItem, FormSuffixIcon, FormTopIcon, Image, ImageContainer, Input, Select, Switch, SwitchItem, SwitchText } from "./FormStyle"

// Antd
import { notification } from 'antd';


// Color
import Colors from "@common/Colors"

// Icon
import Icon from "@common/components/Icon"

// Formik
import getCategory from "@common/api/category/getCategory"
import putUpdatePodcaps from "@common/api/podcaps/putUpdatePodcaps"
import getUsers from "@common/api/user/getUsers"
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import { clearDrawerSlice } from "@redux/slices/drawerSlice"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { SecondaryButton } from "@common/components/buttons/SecondaryButton/SecondaryButton"
import deletePodcaps from "@common/api/podcaps/deletePodcaps"


const { Option } = Select

export default function Form({
    podcapsData
}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const [podcapsId, setPodcapsId] = useState("")
    const [image, setImage] = useState(null)
    const [imageToShow, setImageToShow] = useState(null)
    const [video, setVideo] = useState(null)
    const [duration, setDuration] = useState("")
    const [user, setUser] = useState(null)
    const [userArray, setUserArray] = useState([])
    const [description, setDescription] = useState("")
    const [podcastId, setPodcastId] = useState("")
    const [link, setLink] = useState("")
    const [linkText, setLinkText] = useState("")
    const [tag, setTag] = useState("")
    const [taggedUsers, setTaggedUsers] = useState([])
    const [taggedUsersArray, setTaggedUsersArray] = useState([])
    const [categories, setCategories] = useState([])
    const [categoriesArray, setCategoriesArray] = useState([])
    const [initialCategoriesArray, setInitialCategoriesArray] = useState([])
    const [language, setLanguage] = useState(null)
    const [isCommentable, setIsCommentable] = useState(false)
    const [isPartnership, setIsPartnership] = useState(false)
    const [isAd, setIsAd] = useState(false)

    const [submitClicked, setSubmitClicked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)


    const searchRef = useRef()

    useEffect(() => {
        getInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [podcapsData])


    const setInitialData = async () => {
        if (podcapsData?.ImageUrl) {
            const response = await fetch(podcapsData?.ImageUrl, { mode: "no-cors" })
            const data = await response.blob()
            const ext = podcapsData?.ImageUrl.split(".").pop()
            const filename = podcapsData?.ImageUrl.split("/").pop()
            const metadata = { type: `video/${ext}` }
            const newFile = new File([data], filename, metadata)
            setImage(newFile)
            setImageToShow(podcapsData?.ImageUrl)
        }
        if (podcapsData?.Url) {
            const response = await fetch(podcapsData?.Url, { mode: "no-cors" })
            const data = await response.blob()
            const ext = podcapsData?.Url.split(".").pop()
            const filename = podcapsData?.Url.split("/").pop()
            const metadata = { type: `image/${ext}` }
            const newFile = new File([data], filename, metadata)
            setVideo(newFile)
        }
        if (podcapsData?.Id) {
            setPodcapsId(podcapsData?.Id)
        }
        if (podcapsData?.Duration) {
            setDuration(podcapsData?.Duration)
        }
        if (podcapsData?.User) {
            setUser(JSON.stringify(podcapsData?.User))
        }
        if (podcapsData?.Title) {
            setDescription(podcapsData?.Title)
        }
        if (podcapsData?.PodcastId) {
            setPodcastId(podcapsData?.PodcastId)
        }
        if (podcapsData?.Link) {
            setLink(podcapsData?.Link)
        }

        if (podcapsData?.LinkText) {
            setLinkText(podcapsData?.LinkText)
        }

        if (podcapsData?.Tags?.length > 0) {
            setTag(podcapsData?.Tags[0]?.Text)
        }
        if (podcapsData?.TaggedUserIds?.length > 0) {
            setTaggedUsers(podcapsData?.TaggedUserIds)
        }
        if (podcapsData?.Categories) {
            setCategories(podcapsData?.Categories)
        }

        if (podcapsData?.IsCommentable !== undefined) {
            setIsCommentable(!podcapsData?.IsCommentable)
        }
        setIsPartnership(podcapsData?.IsPartnership ?? false)
        setIsAd(podcapsData?.IsAd ?? false)


        if (podcapsData?.CountryCode) {
            setLanguage(podcapsData?.CountryCode)
        }

    }

    const getInitialData = async () => {
        const userResponse = await getUsers({ text: "", take: 5 })

        if (userResponse?.responseJson) {
            setUserArray(userResponse?.responseJson)
            if (podcapsData?.TaggedUsers?.length > 0) {
                setTaggedUsersArray([...podcapsData?.TaggedUsers, userResponse?.responseJson])
            } else {
                setTaggedUsersArray(userResponse?.responseJson)
            }
        }

        const categoryResponse = await getCategory()

        if (categoryResponse?.responseJson) {
            setCategoriesArray(categoryResponse?.responseJson)
            setInitialCategoriesArray(categoryResponse?.responseJson)
        }
    }


    // const handleOnImageSelectClick = () => {
    //     document.getElementById('image').click()
    // }

    // const handleOnVideoSelectClick = () => {
    //     document.getElementById('video').click()
    // }

    const onImageSelect = event => {
        setImage(event.target.files[0])
        if (event.target.files && event.target.files[0]) {
            setImageToShow(URL.createObjectURL(event.target.files[0]))
        }
    }

    const onVideoSelect = event => {
        setVideo(event.target.files[0])
    }

    const handleOnUserSearch = async (e) => {
        clearTimeout(searchRef.current)
        searchRef.current = setTimeout(async () => {
            const response = await getUsers({ text: e, take: 5 })

            if (response?.responseJson) {
                setUserArray(response?.responseJson)
            }
        }, 300)
    }

    const handleOnTagUsersSearch = (e) => {
        clearTimeout(searchRef.current)
        searchRef.current = setTimeout(async () => {
            const response = await getUsers({ text: e, take: 5 })
            if (response?.responseJson) {
                setTaggedUsersArray(response?.responseJson)
            }
        }, 300)
    }

    const handleOnCategoriesSearch = (e) => {
        if (e?.length > 0) {
            const newData = categoriesArray?.filter(cat => cat?.Text?.includes(e))

            setCategoriesArray(newData)
        } else {
            setCategoriesArray(initialCategoriesArray)
        }
    }


    const handleOnDelete = async () => {
        if (!isLoadingDelete) {
            setIsLoadingDelete(true)
            const response = await deletePodcaps({ id: podcapsId })
            if (response) {
                searchParams.set("refresh", Math.floor(Math.random() * 100))
                navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                dispatch(clearDrawerSlice())
                notification.success({
                    message: 'Başarılı',
                    description:
                        'Podcaps başarıyla silindi.',
                });
                setIsLoadingDelete(false)
            } else {
                notification.error({
                    message: 'Hata',
                    description:
                        'Podcaps silinirken hata oluştu.',
                });
                setIsLoadingDelete(false)
            }
        }

    }

    const handleOnSubmit = async () => {
        setSubmitClicked(true)

        if (image?.name && video?.name && duration?.toString()?.length > 0 && description?.length > 0 && user && language?.length > 0 && podcapsId) {
            setIsLoading(true)
            const response = await putUpdatePodcaps({
                id: podcapsId,
                image: image,
                video: video,
                userId: JSON.parse(user)?.Id,
                duration: parseInt(duration),
                title: description,
                isCommentable: !isCommentable,
                isPartnership: isPartnership,
                isAd: isAd,
                link: link,
                linkText: linkText,
                podcastId: podcastId !== "" ? podcastId : null,
                categories: categories,
                language: language,
                userIds: taggedUsers,
                tag: tag,
            })

            if (response) {
                searchParams.set("refresh", Math.floor(Math.random() * 100))
                navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                dispatch(clearDrawerSlice())

                notification.success({
                    message: 'Başarılı',
                    description:
                        'Podcaps başarıyla düzenlendi.',
                });

            } else {
                setIsLoading(false)
                notification.error({
                    message: 'Hata',
                    description:
                        'Podcaps güncellenirken hata oluştu.',
                });
            }
        }
    }



    return (
        <Container>
            <ImageContainer>
                {
                    imageToShow ?
                        <Image src={imageToShow} />
                        :
                        <EmptyImage errors={submitClicked} />
                }

                {/* <ImageBadge onClick={() => handleOnImageSelectClick()}>
                    <Icon
                        name="plus-circle-outline"
                        width="20"
                        height="20"
                        color={Colors.background}
                    />
                </ImageBadge> */}
            </ImageContainer>

            <FormItem
            // onClick={() => handleOnVideoSelectClick()}
            >
                <FormIcon>
                    <Icon
                        name="play-circle-outline"
                        width="20"
                        height="20"
                        color={
                            video?.name
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <FileInput
                    name="Video"
                    onChange={(val) => setDescription(val)}
                    hasValue={video?.name}
                    errors={(!video?.name && submitClicked).toString()}
                >
                    {video?.name ?? "Video Dosyası"}
                </FileInput>

                <FormSuffixIcon>
                    <Icon
                        name="upload-outline"
                        width="20"
                        height="20"
                        color={Colors.white400}
                    />
                </FormSuffixIcon>
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="hourglass-outline"
                        width="20"
                        height="20"
                        color={
                            duration > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>

                <Input
                    type="number"
                    name="duration"
                    placeholder="Süre"
                    onChange={(e) => setDuration(e.currentTarget.value < 0 ? 0 : e.currentTarget.value > 60 ? 60 : e.currentTarget.value)}
                    value={duration}
                    errors={(!duration && submitClicked).toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="user-outline"
                        width="20"
                        height="20"
                        color={
                            user
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>


                <Select
                    showSearch
                    placeholder={"Hesap"}
                    onChange={(val) => setUser(val)}
                    onSearch={(e) => handleOnUserSearch(e)}
                    value={JSON.parse(user)?.UserName}
                    disabled
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                    errors={(user === null && submitClicked).toString()}
                >
                    {
                        userArray?.map((user, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={JSON.stringify(user)}
                                >
                                    {user?.UserName}
                                </Option>
                            )
                        })
                    }
                </Select>
            </FormItem>

            <FormItem>
                <FormTopIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            description?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormTopIcon>
                <BigInput
                    type="text"
                    name="user"
                    rows={3}
                    placeholder="Podcaps Açıklaması"
                    onChange={(e) => setDescription(e.currentTarget.value)}
                    value={description}
                    errors={(!(description.length > 0) && submitClicked).toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="podcast-outline"
                        width="20"
                        height="20"
                        color={
                            podcastId?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="podcastId"
                    placeholder="Podcast Bağlantısı"
                    onChange={(e) => setPodcastId(e.currentTarget.value)}
                    value={podcastId}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="link-outline"
                        width="20"
                        height="20"
                        color={
                            link?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="link"
                    placeholder="Bağlantı Linki"
                    onChange={(e) => setLink(e.currentTarget.value)}
                    value={link}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="link-outline"
                        width="20"
                        height="20"
                        color={
                            linkText?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="linkText"
                    placeholder="Bağlantı Metni"
                    onChange={(e) => setLinkText(e.currentTarget.value)}
                    value={linkText}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="hash-outline"
                        width="20"
                        height="20"
                        color={
                            tag?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>

                <Input
                    type="text"
                    name="tag"
                    placeholder="Etiket"
                    onChange={(e) => setTag(e.currentTarget.value)}
                    value={tag}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="tag-user-outline"
                        width="20"
                        height="20"
                        color={
                            taggedUsers?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>

                <Select
                    placeholder={"Kişiler"}
                    mode="multiple"
                    showSearch
                    filterOption={false}
                    onChange={(val) => setTaggedUsers(val)}
                    onSearch={(e) => handleOnTagUsersSearch(e)}
                    value={taggedUsers}
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                >
                    {
                        taggedUsersArray?.map((taggedUser, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={taggedUser?.Id}
                                >
                                    {taggedUser?.UserName}
                                </Option>
                            )
                        })
                    }
                </Select>
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="grid-circles-outline"
                        width="20"
                        height="20"
                        color={
                            categories?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>

                <Select
                    placeholder={"Kategoriler"}
                    mode="multiple"
                    onChange={(val) => setCategories(val)}
                    onSearch={(e) => handleOnCategoriesSearch(e)}
                    value={categories}
                    filterOption={false}
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                >
                    {
                        categoriesArray?.map((category, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={category?.Id}
                                >
                                    {category?.Text}
                                </Option>
                            )
                        })
                    }
                </Select>
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="translate-outline"
                        width="20"
                        height="20"
                        color={
                            language
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>


                <Select
                    placeholder={"Dil"}
                    onChange={(val) => setLanguage(val)}
                    value={language}
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                    options={[
                        {
                            value: "TR",
                            label: "TR"
                        },
                        {
                            value: "EN",
                            label: "EN"
                        },
                    ]}
                    errors={(!language && submitClicked).toString()}
                />
            </FormItem>

            <SwitchItem>
                <SwitchText>
                    Yorum Yapmayı Kapat
                </SwitchText>

                <Switch
                    onChange={(value) => setIsCommentable(value)}
                    checked={isCommentable}
                />
            </SwitchItem>
            <SwitchItem>
                <SwitchText>
                    Sponsorlu İçerik
                </SwitchText>

                <Switch
                    onChange={(value) => setIsPartnership(value)}
                    checked={isPartnership}
                />
            </SwitchItem>
            <SwitchItem>
                <SwitchText>
                    Reklam
                </SwitchText>

                <Switch
                    onChange={(value) => setIsAd(value)}
                    checked={isAd}
                />
            </SwitchItem>
            <PrimaryButton type={"button"} onClick={() => handleOnSubmit()} name="Kaydet" icon="plus-outline" loading={isLoading} />
            <div style={{ marginTop: 10 }}>
                <SecondaryButton
                    name={"Podcaps Sil"}
                    icon="trash-outline"
                    onClick={handleOnDelete}
                    loading={isLoadingDelete}
                />
            </div>


            <input id="image" style={{ visibility: "hidden" }} type="file" accept="image/*" onChange={onImageSelect} />
            <input id="video" style={{ visibility: "hidden" }} type="file" accept="video/mp4" onChange={onVideoSelect} />
        </Container>
    )
}
