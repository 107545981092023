import Colors from "@common/Colors"
import styled from "styled-components"

export const Container = styled.div`
display: flex;
flex: 1;
flex-direction: column;
padding: 0 40px;
margin-top: 32px;
`

export const Row = styled.div`
display: flex;
flex: 1;
align-items: center;
justify-content: space-between;
padding: 12px 0;
`

export const Key = styled.div`
font-size: 16px;
font-family: "Plus Jakarta Sans";
font-weight: 400;
color: ${Colors.white600};
`

export const Value = styled(Key)`
color: ${Colors.white};
`