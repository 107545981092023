import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

// Redux
import { setCloseDisable } from '@redux/slices/drawerSlice'
import store from '@redux/store'

export default async function uploadVoice({
    mp3,
    m4a,
    duration,
    title,
    titleEN,
    categoryId,
    type = 2
}) {

    store.dispatch(setCloseDisable(true))

    // Create an object of formData
    const formData = new FormData()

    // Update the formData object
    formData.append(
        "mp3",
        mp3,
        mp3.name
    )

    // Update the formData object
    formData.append(
        "m4a",
        m4a,
        m4a.name
    )

    formData.append(
        'jsonString',
        JSON.stringify({
            Title: title,
            CategoryId: categoryId,
            Duration: parseInt(duration),
            Type: type,
            LanguageDetail: [
                { Text: title, Language: "TR" },
                { Text: titleEN, Language: "EN" }
            ]
        })
    )

    const response = await apiCall({
        config: API.VOICE.UPLOAD,
        requestBody: formData,
        contentType: 'multipart/form-data'
    })


    if (response) {
        store.dispatch(setCloseDisable(false))
        return response
    } else {
        store.dispatch(setCloseDisable(false))
        return null
    }

}
