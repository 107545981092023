import logo from "@assets/logo.svg"
import Colors from "@common/Colors"
import { useUserAuth } from "@common/components/auth/AuthContext"
import Icon from "@common/components/Icon"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import menuData from "./data"
import { Container, Logo, LogoutButton, MenuItem } from "./MenuStyle"


export default function Sider() {

    const navigate = useNavigate()
    const location = useLocation()
    const { user, logOut } = useUserAuth()



    const [selectedItem, setSelectedItem] = useState()

    useEffect(() => {
        const pathIndex = menuData?.findIndex(data => data?.path === location.pathname)

        if (pathIndex > -1) {
            setSelectedItem(menuData[pathIndex]?.id)
        }
    }, [location])


    const handleOnItemClick = (data) => {
        navigate(data?.path)
    }


    return (
        <Container>
            <Logo src={logo} width="32" height="32" alt="Poddy" />
            {
                menuData?.map((data, index) => {
                    return (
                        (data.accountType ? user?.AccountType === data.accountType : true) ? <MenuItem
                            key={index}
                            onClick={() => handleOnItemClick(data)}
                            selected={selectedItem === data?.id}
                        >
                            <Icon
                                name={selectedItem === data?.id ?
                                    data?.selectedImageUrl
                                    : data?.imageUrl}
                                width="20"
                                height="20"
                                color={Colors.white}
                            />
                        </MenuItem> : null
                    )
                })
            }
            <LogoutButton onClick={logOut}>
                <Icon
                    name={"login-outline"}
                    width="20"
                    height="20"
                    color={Colors.white}
                />
            </LogoutButton>
        </Container>
    )
}
