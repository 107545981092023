import { getAuth } from "firebase/auth"

export default async function fetchOptions(
    method,
    requestBody,
    contentType = 'application/json'
) {
    const currentUserToken = await getAuth()?.currentUser?.getIdTokenResult()
    const token = currentUserToken?.token

    let fetchOpt = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
        },
        mode: 'cors',
        withCredentials: true
    }

    if (contentType === "application/json") {
        fetchOpt.headers['Content-Type'] = contentType
    }

    if (!!token) {
        fetchOpt['headers']['Authorization'] = `Bearer ${token}`
    }

    if (!!requestBody) {
        fetchOpt['body'] = contentType === "multipart/form-data" ? requestBody : JSON.stringify(requestBody)
    }

    return fetchOpt
}
