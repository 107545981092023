import getUser from "@common/api/user/getUser"
import { clearAuthSlice, setToken } from "@redux/slices/authSlice"
import { notification } from "antd"
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { createContext, useContext, useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import { auth } from "./firebase"

const userAuthContext = createContext()

export function AuthContextProvider({ children }) {

    const dispatch = useDispatch()

    const [user, setUser] = useState(null)
    const [loadingAuth, setLoadingAuth] = useState(true)


    const clear = () => {
        setUser(null)
        setLoadingAuth(false)
        dispatch(clearAuthSlice())
    }


    const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password)
    const createUser = (email, password) => createUserWithEmailAndPassword(auth, email, password)
    const logOut = () => signOut(auth).then(clear)


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {
            if (currentuser) {
                dispatch(setToken(currentuser?.accessToken))
                try {
                    const response = await getUser()
                    if (response && response.status === 200) {
                        setUser(response?.responseJson)
                        setLoadingAuth(false)
                    } else {
                        logOut()
                        notification.error({
                            message: 'Hata',
                            description:
                                'Üzgünüz bir hata oluştu. Lütfen sistem yöneticisi ile iletişime geçin.',
                        })
                        setLoadingAuth(false)
                    }
                } catch (err) {
                    logOut()
                    setLoadingAuth(false)
                }
            } else {
                setLoadingAuth(false)

            }

        })

        return () => {
            unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <userAuthContext.Provider value={{ user, loadingAuth, signIn, createUser, logOut }}>
            {children}
        </userAuthContext.Provider>
    )
}

export function useUserAuth() {
    return useContext(userAuthContext)
}