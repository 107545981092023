import { DatePicker, Select, Switch } from "antd"
import styled from "styled-components"
import Colors from "./Colors"

export const DashboardBox = styled.div`
  display: flex;
  flex-direction: column;
  width: stretch;
  align-self: flex-start;
  border: 1px solid ${Colors.white200};
  border-radius: 12px;
  padding: 24px;
`

export const FormInputItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  justify-content: center;
  position: relative;
  .ant-picker-range{
  outline: none !important;
  border: ${(props) =>
    props?.errors === "true" ? `solid 1px ${Colors.primary}` : "none"};
  box-shadow: none !important;
  background: ${Colors.white100};
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 12px;
  color: ${Colors.white400};
  padding-left: 45px;
  max-height:40px !important;
    :hover{
      .ant-picker-suffix{
        opacity:0
      }
    }
    .ant-picker-active-bar{
      display:none !important
    }
    input{
      color: ${Colors.white};
      font-family: "Plus Jakarta Sans";
      text-align:center;
      &::placeholder {
          color: ${Colors.white400} !important;
        }
    }
    .ant-picker-suffix{
    color: ${Colors.white400};
    }
    .ant-picker-clear{
      background-color:transparent !important;
    }
  }
`

export const FormInputIcon = styled.span`
  position: absolute;
  display: flex;
  margin-left: 15px;
`

export const FormInputSuffixIcon = styled.span`
  position: absolute;
  display: flex;
  align-self: flex-end;
  margin-right: 15px;
`

export const FormInput = styled.input`
  outline: none !important;
  border: ${(props) =>
    props?.errors === "true" ? `solid 1px ${Colors.primary}` : "none"};
  box-shadow: none !important;
  background: ${Colors.white100};
  padding: 10px 17px;
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 12px;
  color: ${Colors.white};
  font-family: "Plus Jakarta Sans";
  padding-left: 45px;
  &::placeholder {
    color: ${Colors.white400};
  }
`

export const FormPhoneInput = styled.input`
  outline: none !important;
  border: ${(props) =>
    props?.errors === "true" ? `solid 1px ${Colors.primary}` : "none"};
  box-shadow: none !important;
  background: ${Colors.white100};
  padding: 10px 17px;
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 12px;
  color: ${Colors.white};
  font-family: "Plus Jakarta Sans";
  padding-left: 45px;
  &::placeholder {
    color: ${Colors.white400};
  }
`

export const FormPhoneInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const FormInputBig = styled.textarea`
  outline: none !important;
  border: ${(props) =>
    props?.errors === "true" ? `solid 1px ${Colors.primary}` : "none"};
  box-shadow: none !important;
  background: ${Colors.white100};
  padding: 10px 17px;
  min-height: 112px;
  box-sizing: border-box;
  border-radius: 12px;
  color: ${Colors.white};
  font-family: "Plus Jakarta Sans";
  padding-left: 45px;
  resize: none !important;
  &::placeholder {
    color: ${Colors.white400};
  }
`

export const FormFile = styled.div`
  display: flex;
  outline: none !important;
  overflow: hidden;
  border: ${(props) =>
    props?.errors === "true" ? `solid 1px ${Colors.primary}` : "none"};
  box-shadow: none !important;
  background: ${Colors.white100};
  padding: 10px 45px;
  min-height: 56px;
  box-sizing: border-box;
  border-radius: 12px;
  color: ${Colors.white};
  font-family: "Plus Jakarta Sans";
  padding-left: 45px;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props?.hasValue ? Colors.white : Colors.white400)};
`

export const Date = styled(DatePicker)`
  outline: none !important;
  border: ${(props) =>
    props?.errors === "true" ? `solid 1px ${Colors.primary}` : "none"};
  box-shadow: none !important;
  background: ${Colors.white100};
  padding: 10px 17px;
  min-height: 56px;
  box-sizing: border-box;
  border-radius: 12px;
  color: ${Colors.white} !important;
  font-family: "Plus Jakarta Sans";
  padding-left: 45px;

  .ant-picker-input > input {
    color: ${Colors.white} !important;
  }

  .ant-picker-input > input::placeholder {
    color: ${Colors.white400} !important;
  }

  .ant-picker-input-placeholder > input {
    color: ${Colors.white400};
  }

  .ant-picker-clear {
    background: transparent;
  }
`

export const FormSwitchItem = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 40px;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 10px;

  .ant-switch {
    background-color: ${Colors.white400};
    &:hover {
      background-color: ${Colors.white200} !important;
    }
  }

  .ant-switch-checked {
    background-color: ${Colors.primary} !important;

    &:hover {
      background-color: ${Colors.primary600} !important;
    }
  }

  .ant-switch-handle {
    width: 16px;
    height: 16px;
    top: 3px;
    inset-inline-start: 6px;
  }
`

export const FormSwitchText = styled.div`
  display: flex;
  flex: 1;
  color: ${Colors.white};
  font-family: "Plus Jakarta Sans";
`

export const FormSwitch = styled(Switch)``

export const FormSelect = styled(Select)`
  outline: none !important;
  border: ${(props) =>
    props?.errors === "true" ? `solid 1px ${Colors.primary}` : "none"};
  box-shadow: none !important;
  background: ${Colors.white100};
  min-height: 56px;
  box-sizing: border-box;
  border-radius: 12px;
  color: ${Colors.white} !important;
  font-family: "Plus Jakarta Sans";
  padding-left: 45px;

  .ant-select-selector {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    min-height: 56px !important;
    box-sizing: border-box;
    border-radius: 12px;
    color: ${Colors.white} !important;
    align-items: center;
    padding: 0 !important;

    .ant-select-selection-item {
      color: ${Colors.white} !important;
      background-color: ${(props) =>
    props?.mode === "multiple" ? `${Colors.white200}` : "transparent"};

      .ant-select-selection-item-remove {
        color: ${Colors.white};
      }
    }

    .ant-select-selection-search {
      inset-inline-start: 0px;
      margin-inline-start: 0px;

      .ant-select-selection-search-input {
        min-height: 56px;
      }
    }

    .ant-select-selection-placeholder {
      inset-inline-start: 0px;
      margin-inline-start: 0px;
      color: ${Colors.white400} !important;
    }
  }
`

export const FormImageItem = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  margin-bottom: 40px;
`

export const FormImage = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 12px;
`
export const FormEmptyImage = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 12px;
  background-color: ${Colors.white100};
  border: ${(props) =>
    props?.errors ? `solid 1px ${Colors.primary}` : "none"};
`

export const FormImageBadge = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  bottom: -14px;
  right: -14px;
  border-radius: 999px;
  background-color: ${Colors.white};
  cursor: pointer;
`

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px 40px;
  box-sizing: border-box;
  .ant-table {
    background: transparent !important;
    margin-bottom: 40px;
    .ant-table-thead {
      background: transparent !important;
      tr {
        background: transparent !important;
        th {
          &::before {
            display: none !important;
          }
          background: transparent !important;
          color: ${Colors.white400} !important;
          font-family: "Plus Jakarta Sans";
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          border-bottom: 0.5px solid ${Colors.white100} !important;
        }
        td {
          border-bottom: 0.5px solid ${Colors.white100} !important;
          background: transparent !important;
        }
      }
    }
    .ant-table-tbody {
      tr {
        &:hover {
          background: transparent !important;
          td {
            background: transparent !important;
          }
        }
        td {
          font-family: "Plus Jakarta Sans";
          color: ${Colors.white} !important;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          border-bottom: 0.5px solid ${Colors.white100} !important;
        }
      }
      .ant-table-placeholder {
        background: transparent !important;
        .ant-empty-description {
          color: ${Colors.white} !important;
        }
      }
    }
  }
  .ant-pagination {
    display: flex;
    float: right;
    justify-content: flex-end;
    li {
      a {
        color: ${Colors.white};
      }
    }
    .ant-pagination-item-active {
      border: none;
      a {
        color: ${Colors.black};
      }
    }
    .ant-pagination-item-ellipsis {
      color: ${Colors.white} !important;
    }
    .ant-pagination-item-link-icon {
      color: ${Colors.white} !important;
    }
  }
`

export const TableTitle = styled.div`
  display: flex;
`

export const TableTitleImage = styled.div`
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: 12px;
  overflow: hidden;
  min-width: 80px;
  min-height: 80px;
  background-color: ${Colors.white100};
`

export const TableTitleText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${Colors.white};
    padding: 0;
    margin: 0;
  }
  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.white400};
    padding: 0;
    margin: 0;
  }
`
