import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function getTeamSearchCount({ text, userId }) {

    let parameters = `?text=${text ?? ""}`

    if (userId) {
        parameters = parameters + `&id=${userId}`
    }

    const response = await apiCall({
        config: API.USER.GET_TEAM_SEARCH_COUNT,
        parameters: parameters
    })

    if (response) {
        return response
    } else {
        return null
    }

}
