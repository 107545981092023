import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function getDashboardByUserId({ userId }) {

    const parameters = `?userId=${userId}`

    const response = await apiCall({
        config: API.DASHBOARD.GET_BY_USER_ID,
        parameters: parameters
    })

    if (response) {
        return response
    } else {
        return null
    }

}
