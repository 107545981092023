const data = [
    {
        id: 1,
        title: "Dashboard",
        path: "/",
        imageUrl: "home-outline",
        selectedImageUrl: "home-solid",
        accountType: 2
    },
    {
        id: 2,
        title: "Partners",
        path: "/partners",
        imageUrl: "users-outline",
        selectedImageUrl: "users-solid",
        accountType: 1
    },
    // {
    //     id: 2,
    //     title: "Users",
    //     path: "/users",
    //     imageUrl: "users-outline",
    //     selectedImageUrl: "users-solid",
    //     permissionParam: "IsAuthorizeForUserTab"
    // },
    // {
    //     id: 3,
    //     title: "Podcaps",
    //     path: "/podcaps",
    //     imageUrl: "podcaps-outline",
    //     selectedImageUrl: "podcaps-solid",
    //     permissionParam: "IsAuthorizeForPodcapsTab"
    // },
    // {
    //     id: 4,
    //     title: "Podcast",
    //     path: "/podcast",
    //     imageUrl: "podcast-outline",
    //     selectedImageUrl: "podcast-solid",
    //     permissionParam: "IsAuthorizeForShowTab"
    // },
    // {
    //     id: 5,
    //     title: "Complaint",
    //     path: "/complaint",
    //     imageUrl: "complain-outline",
    //     selectedImageUrl: "complain-solid",
    //     permissionParam: "IsAuthorizeForComplaintTab"
    // },
    // {
    //     id: 6,
    //     title: "Vomoji",
    //     path: "/vomoji",
    //     imageUrl: "chat-voice-outline",
    //     selectedImageUrl: "chat-voice-solid",
    //     permissionParam: "IsAuthorizeForSettingsTab"
    // },
    // {
    //     id: 7,
    //     title: "Rss",
    //     path: "/rss",
    //     imageUrl: "text-speech-outline",
    //     selectedImageUrl: "text-speech-outline",
    //     permissionParam: "IsAuthorizeForRss"
    // },
    // {
    //     id: 8,
    //     title: "Verification",
    //     path: "/verification",
    //     imageUrl: "verified-outline",
    //     selectedImageUrl: "verified-solid",
    //     permissionParam: "IsAuthorizeForRss"
    // },
]

export default data