import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    drawerVisible: false,
    drawerContent: null,
    drawerProps: null,
    drawerCloseDisable: false,
}

const drawerSlice = createSlice({
    name: 'drawer',
    initialState: initialState,
    reducers: {

        //!Clear drawer Slice
        clearDrawerSlice: () => initialState,

        setDrawerVisibility: (state, action) => {
            state.drawerVisible = action.payload
        },

        setDrawerContent: (state, action) => {
            state.drawerContent = action.payload
        },

        setDrawerProps: (state, action) => {
            state.drawerProps = action.payload
        },

        setCloseDisable: (state, action) => {
            state.drawerCloseDisable = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    clearDrawerSlice,
    setDrawerVisibility,
    setDrawerContent,
    setDrawerProps,
    setCloseDisable
} = drawerSlice.actions

export default drawerSlice.reducer
