import styled from "styled-components"

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 0 40px;
margin-top: 32px;
gap: 30px;
`

export const Button = styled.div`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`
