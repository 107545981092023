import { configureStore } from '@reduxjs/toolkit'

import authSlice from './slices/authSlice'
import drawerSlice from './slices/drawerSlice'
import userSlice from './slices/userSlice'

// ALFABETİK :(
const store = configureStore({
    reducer: {
        auth: authSlice,
        drawer: drawerSlice,
        user: userSlice
    },
    devTools: true
})

export default store
