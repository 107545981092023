export const API_STATUS = {
    NONE: 0,
    REQUEST: 1,
    SUCCESS: 2,
    FAILURE: 3,
}

export const DRAWER_CONTENT = {
    PARTNER_CREATE: 1,
    USER_CREATE: 2,
    PODCAPS_CREATE: 3,
    PODCAPS_EDIT: 4,
    SHOW_DETAIL: 5,
    SHOW_CREATE: 6,
    VOMOJI_DETAIL: 7,
    VOMOJI_CREATE: 8,
    RSS_CREATE: 9,
    VERIFICATION_DETAIL: 10
}

export const ACCOUNT_TYPE = {
    NORMAL: 0,
    VERIFIED: 1,
    NOT_CLAIMED: 2
}

export const GENDER = {
    NO_GENDER: { value: 0, label: "Belirtmek İstemiyorum" },
    MAN: { value: 1, label: "Erkek" },
    WOMAN: { value: 2, label: "Kadın" },
}

export const REGISTER_TYPE = {
    PHONE: 1,
    GOOGLE: 2,
    FACEBOOK: 3,
    APPLE: 4
}

export const RSS_TYPE = {
    GENERAL: "1",
    PARTNER: "2",
}