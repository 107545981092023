
// Style
import { Container } from "./DetailContentStyle"


// Components
import Detail from "../Detail/Detail"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"


export default function DetailContent({
    userData
}) {

    return (
        <Container>
            <Header />

            <Detail
                userData={userData}
            />

            <Footer
                userData={userData}
            />
        </Container>
    )
}
