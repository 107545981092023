import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function postCreateRssPartner({ requestBody }) {

    const response = await apiCall({
        config: API.RSS.POST_CREATE_RSS_PARTNER,
        requestBody: requestBody,
    })

    if (response) {
        return response
    } else {
        return null
    }

}
