import Form from "../Form/Form"
import Header from "../Header/Header"
import { Container } from "./CategoryChangeContentStyle"

export default function EditContent({
    setIsItemCategoryChange,
    isItemEditData
}) {

    return (
        <Container>
            <Header
                setIsItemCategoryChange={setIsItemCategoryChange}
            />

            <Form
                isItemEditData={isItemEditData}
            />
        </Container>
    )
}
