import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function getVoiceCategory() {

    const parameters = `?type=2`

    const response = await apiCall({
        config: API.VOICE_CATEGORY.GET,
        parameters: parameters,
    })

    if (response) {
        return response
    } else {
        return null
    }

}
