import Logo from "@assets/logo-text.svg"
import Colors from "@common/Colors"
import { useUserAuth } from "@common/components/auth/AuthContext"
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import Icon from "@common/components/Icon"
import { loginValidation } from "@helpers/validations"
import { notification } from "antd"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import {
    Container, Divider, Form, FormIcon, FormItem, FormTitle, Header,
    HeaderTitle, Input
} from "./LoginViewStyle"

export default function LoginView() {

    const navigate = useNavigate()
    const { signIn } = useUserAuth()

    const handleLogin = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginValidation,
        onSubmit: async (values) => {
            try {
                await signIn(values.email, values.password)
                navigate("/")
            } catch (err) {
                if (err.code === "auth/wrong-password") {
                    notification.error({
                        message: 'Hata',
                        description:
                            'Yanlış şifre girdiniz, lütfen tekrar deneyin.',
                    })
                } else {
                    notification.error({
                        message: 'Hata',
                        description:
                            'Üzgünüz bir hata oluştu. Lütfen sistem yöneticisi ile iletişime geçin.',
                    })
                }
            }
        },

    })


    return (
        <Container>
            <Header>
                <img src={Logo} width="186" alt="Poddy" />

                <Divider />

                <HeaderTitle>
                    Yönetim Paneli
                </HeaderTitle>
            </Header>

            <Form onSubmit={handleLogin.handleSubmit}>
                <FormTitle>Giriş</FormTitle>
                <FormItem>
                    <FormIcon>
                        <Icon
                            name="email-outline"
                            width="20"
                            height="20"
                            color={
                                handleLogin.values.email.length > 0
                                    ? Colors.white
                                    : Colors.white400
                            }
                        />
                    </FormIcon>
                    <Input
                        type="email"
                        name="email"
                        placeholder="E-posta Adresi"
                        onChange={handleLogin.handleChange}
                        value={handleLogin.values.email}
                        errors={((handleLogin.errors.email && handleLogin.touched.email) ?? "").toString()}
                    />
                </FormItem>
                <FormItem>
                    <FormIcon>
                        <Icon
                            name="lock-outline"
                            width="20"
                            height="20"
                            color={
                                handleLogin.values.password.length > 0
                                    ? Colors.white
                                    : Colors.white400
                            }
                        />
                    </FormIcon>
                    <Input
                        type="password"
                        name="password"
                        placeholder="Şifre"
                        onChange={handleLogin.handleChange}
                        value={handleLogin.values.password}
                        errors={((handleLogin.errors.password && handleLogin.touched.password) ?? "").toString()}

                    />
                </FormItem>
                <PrimaryButton onClick={handleLogin.handleSubmit} name="Giriş Yap" icon="arrow-right-outline" />
            </Form>
        </Container>
    )
}