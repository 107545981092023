import getPodcastById from "@common/api/podcast/getPodcastGetById"
import { useEffect, useState } from "react"
import EditContent from "./EditContent/EditContent"

export default function PodcastEditDrawer({
    podcastToEdit,
    setIsPodcastEdit,
    setPodcastToEdit
}) {

    const [podcastData, setPodcastData] = useState(null)

    useEffect(() => {
        if (podcastToEdit) {
            const getData = async () => {
                const response = await getPodcastById({ id: podcastToEdit })

                if (response?.responseJson) {
                    setPodcastData(response?.responseJson)
                }
            }

            getData()
        }
    }, [podcastToEdit])

    return (
        <EditContent
            podcastData={podcastData}
            setIsPodcastEdit={setIsPodcastEdit}
            setPodcastToEdit={setPodcastToEdit}
        />
    )
}
