
// Style
import { Container } from "./DetailContentStyle"

// Components
import Controls from "../Controls/Controls"
import Detail from "../Detail/Detail"
import Header from "../Header/Header"
import ShowInfo from "../ShowInfo/ShowInfo"

export default function DetailContent({
    setIsEdit,
    setIsItemCreate,
    propsData,
    setIsItemEdit,
    setIsItemEditData,
    setIsItemCategoryChange,
    items,
}) {
    return (
        <Container>
            <Header />

            <ShowInfo
                propsData={propsData}
            />

            <Controls
                propsData={propsData}
                setIsEdit={setIsEdit}
                setIsItemCreate={setIsItemCreate}
            />

            <Detail
                setIsItemEdit={setIsItemEdit}
                setIsItemCategoryChange={setIsItemCategoryChange}
                setIsItemEditData={setIsItemEditData}
                items={items}
            />
        </Container>
    )
}
