import { useSelector } from "react-redux"
import EditContent from "./EditContent/EditContent"

export default function PodcapsEditDrawer() {

    const drawerProps = useSelector(state => state.drawer.drawerProps)

    return (
        <EditContent
            podcapsData={drawerProps?.podcapsData}
        />
    )
}
