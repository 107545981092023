import Logo from '@assets/poddy-200.gif'
import Colors from "@common/Colors"
import Icon from "@common/components/Icon"
import { Button } from "./PrimaryButtonStyle"

export const PrimaryButton = ({ name, icon, onClick, type = "submit", loading, disabled, fullWidth = true }) => {
    return (
        <Button
            type={type}
            onClick={onClick}
            disabled={loading || disabled}
            fullWidth={fullWidth}
        >
            {
                loading ?
                    <img src={Logo} width={20} height={20} alt="poddy-gif" />
                    :
                    <Icon
                        name={icon}
                        width="20"
                        height="20"
                        color={Colors.white}
                    />
            }

            {name}
        </Button>
    )
}
