// Components
import getDashboard from "@common/api/dashboard/getDashboard"
import InvitationLink from "@components/admin/dashboard/InvitationLink/InvitationLink"
import MyTotalGainedUsers from "@components/admin/dashboard/MyTotalGainedUsers/MyTotalGainedUsers"
import TeamTable from "@components/admin/dashboard/TeamTable/TeamTable"
import TeamTotalGainedUsers from "@components/admin/dashboard/TeamTotalGainedUsers/TeamTotalGainedUsers"
import TotalGainedUsers from "@components/admin/dashboard/TotalGainedUsers/TotalGainedUsers"
import { useEffect, useState } from "react"
import { Column, Container, Row } from "./DashboardViewStyle"

export default function DashboardView() {

    const [dashboardData, setDashboardData] = useState(null)

    useEffect(() => {

        const getDashboardData = async () => {
            const response = await getDashboard()

            setDashboardData(response.responseJson)
        }


        getDashboardData()
    }, [])

    return (
        <Container>
            <Column>
                <TotalGainedUsers dashboardData={dashboardData} />

                <Row>
                    <MyTotalGainedUsers dashboardData={dashboardData} />
                    <TeamTotalGainedUsers dashboardData={dashboardData} />
                </Row>

                <InvitationLink />
            </Column>

            <Column>
                <TeamTable />
            </Column>
        </Container>
    )
}
