import Colors from "@common/Colors"
import styled from "styled-components"

export const Container = styled.div`
display: flex;
flex: 1;
flex-direction: row;
align-items: center;
gap: 12px;
padding: 31px 40px;
`

export const Title = styled.div`
flex: 1;
font-size: 24px;
font-weight: 600;
font-family: "Plus Jakarta Sans";
color: ${Colors.white};
`

export const BackButton = styled.div`
cursor: pointer;
`