import Colors from "@common/Colors"
import styled from "styled-components"

export const Container = styled.div`
display: flex;
flex: 1;
flex-direction: column;
padding: 0 40px;
margin-top: 32px;
`

export const Item = styled.div`
display: flex;
flex: 1;
flex-direction: column;
border-top: 1px solid ${Colors.white100};
padding: 15px 0px;
gap: 15px;
`

export const Row = styled.div`
display: flex;
flex: 1;
align-items: center;
justify-content: space-between;
gap: 15px;
`

export const Image = styled.img`
width: 80px;
height: 80px;
border-radius: 12px;
`

export const Title = styled.div`
display: flex;
flex: 1;
font-size: 16px;
font-family: "Plus Jakarta Sans";
font-weight: 400;
color: ${Colors.white600};
`

export const Date = styled.div`
font-size: 13px;
font-family: "Plus Jakarta Sans";
font-weight: 400;
color: ${Colors.white600};
`

export const Controls = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 15px;
`

export const Button = styled.div`
cursor: ${(props) =>
        props?.disable ? "wait" : "pointer"};
`

export const LoadMore = styled.div`
margin-top: 20px;
margin-bottom: 40px;
text-align: center;
font-size: 13px;
font-weight: 400;
color: ${Colors.white400};
cursor: pointer;
display: ${(props) =>
        props?.disable ? "none" : "block"};

`