import { useEffect, useRef, useState } from "react"

// Style
import { BigInput, Container, EmptyImage, File, FormIcon, FormItem, FormSuffixIcon, FormTopIcon, Image, ImageBadge, ImageContainer, Input, Select, Switch, SwitchItem, SwitchText } from "./FormStyle"

// Helpers

// Color
import Colors from "@common/Colors"

// Icon
import Icon from "@common/components/Icon"

// Formik
import getCategory from "@common/api/category/getCategory"
import postUploadPodcast from "@common/api/podcast/postUploadPodcast"
import getUsers from "@common/api/user/getUsers"
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import { clearDrawerSlice } from "@redux/slices/drawerSlice"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { notification } from "antd"


const { Option } = Select

export default function Form({
    showData
}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const [image, setImage] = useState(null)
    const [imageToShow, setImageToShow] = useState(null)
    const [sound, setSound] = useState(null)
    const [duration, setDuration] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [taggedUsers, setTaggedUsers] = useState([])
    const [taggedUsersArray, setTaggedUsersArray] = useState([])
    const [categories, setCategories] = useState([])
    const [categoriesArray, setCategoriesArray] = useState([])
    const [initialCategoriesArray, setInitialCategoriesArray] = useState([])
    const [isCommentable, setIsCommentable] = useState(false)
    const [isPartnership, setIsPartnership] = useState(false)
    const [submitClicked, setSubmitClicked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const searchRef = useRef()

    useEffect(() => {
        getInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInitialData = async () => {
        const userResponse = await getUsers({ text: "", take: 5 })

        if (userResponse?.responseJson) {
            setTaggedUsersArray(userResponse?.responseJson)
        }

        const categoryResponse = await getCategory()

        if (categoryResponse?.responseJson) {
            setCategoriesArray(categoryResponse?.responseJson)
            setInitialCategoriesArray(categoryResponse?.responseJson)
        }
    }


    const handleOnImageSelectClick = () => {
        document.getElementById('image').click()
    }

    const handleOnSoundSelectClick = () => {
        document.getElementById('sound').click()
    }

    const onImageSelect = event => {
        setImage(event.target.files[0])
        if (event.target.files && event.target.files[0]) {
            setImageToShow(URL.createObjectURL(event.target.files[0]))
        }
    }

    const onSoundSelect = event => {
        setSound(event.target.files[0])
    }

    const handleOnTagUsersSearch = (e) => {
        clearTimeout(searchRef.current)
        searchRef.current = setTimeout(async () => {
            const response = await getUsers({ text: e, take: 5 })

            if (response?.responseJson) {
                setTaggedUsersArray(response?.responseJson)
            }
        }, 300)
    }

    const handleOnCategoriesSearch = (e) => {
        if (e?.length > 0) {
            const newData = categoriesArray?.filter(cat => cat?.Text?.includes(e))

            setCategoriesArray(newData)
        } else {
            setCategoriesArray(initialCategoriesArray)
        }
    }

    const handleOnSubmit = async () => {
        setSubmitClicked(true)
        if (image?.name && sound?.name && description?.length > 0 && title?.length > 0 && duration?.toString()?.length > 0) {
            setIsLoading(true)
            const response = await postUploadPodcast({
                userId: showData?.UserId,
                image: image,
                sound: sound,
                duration: duration,
                title: title,
                showId: showData?.Id,
                description: description,
                isCommentable: !isCommentable,
                isPartnership: isPartnership,
                categories: categories,
                userIds: taggedUsers,
            })

            if (response) {
                searchParams.set("refresh", Math.floor(Math.random() * 100))
                navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                dispatch(clearDrawerSlice())
                notification.success({
                    message: 'Başarılı',
                    description:
                        'Podcast başarıyla yüklendi.',
                });
            } else {
                setIsLoading(false)
                notification.error({
                    message: 'Hata',
                    description:
                        'Podcast yüklenirken hata oluştu.',
                });
            }
        }
    }



    return (
        <Container>
            <ImageContainer>
                {
                    imageToShow ?
                        <Image src={imageToShow} />
                        :
                        <EmptyImage errors={submitClicked} />
                }

                <ImageBadge onClick={() => handleOnImageSelectClick()}>
                    <Icon
                        name="plus-circle-outline"
                        width="20"
                        height="20"
                        color={Colors.background}
                    />
                </ImageBadge>
            </ImageContainer>

            <FormItem onClick={() => handleOnSoundSelectClick()}>
                <FormIcon>
                    <Icon
                        name="note-outline"
                        width="20"
                        height="20"
                        color={
                            sound?.name
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <File
                    name="Sound"
                    onChange={(val) => setSound(val)}
                    hasValue={sound?.name}
                    errors={(!sound?.name && submitClicked).toString()}
                >
                    {sound?.name ?? "Ses Dosyası"}
                </File>

                <FormSuffixIcon>
                    <Icon
                        name="upload-outline"
                        width="20"
                        height="20"
                        color={Colors.white400}
                    />
                </FormSuffixIcon>
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="hourglass-outline"
                        width="20"
                        height="20"
                        color={
                            duration > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="number"
                    name="duration"
                    placeholder="Süre"
                    onChange={(e) => setDuration(e.currentTarget.value < 0 ? 0 : e.currentTarget.value)}
                    value={duration}
                    errors={(!duration && submitClicked).toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="podcast-outline"
                        width="20"
                        height="20"
                        color={
                            title?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="title"
                    placeholder="Podcast Başlığı"
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    value={title}
                    errors={((!title || title === "") && submitClicked).toString()}
                />
            </FormItem>


            <FormItem>
                <FormTopIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            description?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormTopIcon>
                <BigInput
                    type="text"
                    name="user"
                    rows={3}
                    placeholder="Podcast Açıklaması"
                    onChange={(e) => setDescription(e.currentTarget.value)}
                    value={description}
                    errors={(!(description?.length > 0) && submitClicked).toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="tag-user-outline"
                        width="20"
                        height="20"
                        color={
                            taggedUsers?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>

                <Select
                    placeholder={"Kişiler"}
                    mode="multiple"
                    showSearch
                    filterOption={false}
                    onChange={(val) => setTaggedUsers(val)}
                    onSearch={(e) => handleOnTagUsersSearch(e)}
                    value={taggedUsers}
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                >
                    {
                        taggedUsersArray?.map((taggedUser, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={taggedUser?.Id}
                                >
                                    {taggedUser?.UserName}
                                </Option>
                            )
                        })
                    }
                </Select>
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="grid-circles-outline"
                        width="20"
                        height="20"
                        color={
                            categories?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>

                <Select
                    placeholder={"Kategoriler"}
                    mode="multiple"
                    onChange={(val) => setCategories(val)}
                    onSearch={(e) => handleOnCategoriesSearch(e)}
                    value={categories}
                    filterOption={false}
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                >
                    {
                        categoriesArray?.map((category, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={category?.Id}
                                >
                                    {category?.Text}
                                </Option>
                            )
                        })
                    }
                </Select>
            </FormItem>

            <SwitchItem>
                <SwitchText>
                    Yorum Yapmayı Kapat
                </SwitchText>

                <Switch
                    onChange={(value) => setIsCommentable(value)}
                    checked={isCommentable}
                />
            </SwitchItem>

            <SwitchItem>
                <SwitchText>
                    Sponsorlu İçerik
                </SwitchText>

                <Switch
                    onChange={(value) => setIsPartnership(value)}
                    checked={isPartnership}
                />
            </SwitchItem>

            <PrimaryButton type={"button"} onClick={() => handleOnSubmit()} name="Kaydet" icon="plus-outline" loading={isLoading} />

            <input id="image" style={{ visibility: "hidden" }} type="file" accept="image/*" onChange={onImageSelect} />
            <input id="sound" style={{ visibility: "hidden" }} type="file" accept="audio/*" onChange={onSoundSelect} />
        </Container>
    )
}
