import { useEffect, useState } from "react"

// Style
import { Container, FormIcon, FormItem, Select } from "./FormStyle"

// Color
import Colors from "@common/Colors"

// Icon
import Icon from "@common/components/Icon"

// Formik
import updateVoice from "@common/api/voice/updateVoice"
import getVoiceCategory from "@common/api/voiceCategory/getVoiceCategory"
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import { clearDrawerSlice } from "@redux/slices/drawerSlice"
import { notification } from "antd"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

const { Option } = Select

export default function Form({
    isItemEditData
}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const [category, setCategory] = useState([])
    const [categoriesArray, setCategoriesArray] = useState([])
    const [initialCategoriesArray, setInitialCategoriesArray] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInitialData = async () => {
        const categoryResponse = await getVoiceCategory()

        if (categoryResponse?.responseJson) {
            setCategoriesArray(categoryResponse?.responseJson)
            setInitialCategoriesArray(categoryResponse?.responseJson)
        }
    }

    const handleOnCategoriesSearch = (e) => {
        if (e?.length > 0) {
            const newData = categoriesArray?.filter(cat => cat?.Text?.includes(e))

            setCategoriesArray(newData)
        } else {
            setCategoriesArray(initialCategoriesArray)
        }
    }


    const handleOnSubmit = async () => {

        const response = await updateVoice({
            requestBody: {
                CategoryId: category,
                Id: isItemEditData?.Id
            }
        })

        if (response) {
            searchParams.set("refresh", Math.floor(Math.random() * 100))
            navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
            dispatch(clearDrawerSlice())
            notification.success({
                message: 'Başarılı',
                description:
                    'Vomoji başarıyla güncellendi.',
            })
        } else {
            setIsLoading(false)
            notification.error({
                message: 'Hata',
                description:
                    'Vomoji güncellenirken hata oluştu.',
            })
        }
    }

    return (
        <Container>
            <FormItem>
                <FormIcon>
                    <Icon
                        name="grid-circles-outline"
                        width="20"
                        height="20"
                        color={
                            category?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>

                <Select
                    placeholder={"Kategoriler"}
                    onChange={(val) => setCategory(val)}
                    onSearch={(e) => handleOnCategoriesSearch(e)}
                    value={category}
                    filterOption={false}
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                >
                    {
                        categoriesArray?.map((category, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={category?.Id}
                                >
                                    {category?.Text}
                                </Option>
                            )
                        })
                    }
                </Select>
            </FormItem>

            <PrimaryButton type={"button"} onClick={() => handleOnSubmit()} name="Kaydet" icon="plus-outline" loading={isLoading} />
        </Container>
    )
}
