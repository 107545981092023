// Style
import { Container, Image, Title, Username } from "./ShowInfoStyle"

export default function ShowInfo({
    propsData
}) {

    return (
        <Container>
            {
                propsData?.ImageUrl &&
                <Image
                    src={propsData?.ImageUrl}
                />
            }

            <Title>
                {propsData?.Text}
            </Title>

            <Username>
                {propsData?.VoiceCount} Vomoji
            </Username>

        </Container>
    )
}
