import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,

}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {

        //!Clear Auth Slice
        clearAuthSlice: () => initialState,

        setToken: (state, action) => {
            state.token = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    clearAuthSlice,
    setToken,
} = authSlice.actions

export default authSlice.reducer
