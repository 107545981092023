import Colors from "@common/Colors"
import { FormInput, FormInputIcon, FormInputItem, FormSelect } from "@common/style"
import styled from "styled-components"

export const Input = styled(FormInput)``

export const FormIcon = styled(FormInputIcon)``

export const FormItem = styled(FormInputItem)`
  width: 240px;
  margin-left: 16px;
  margin-bottom: 0px;
  &:first-child {
    margin-left: 0px;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Top = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.white100};
  margin-bottom: 40px;
  padding: 24px 40px;
  box-sizing: border-box;
`

export const Title = styled.div`
  color: ${Colors.white};
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
  margin-left: 12px;
`

export const ButtonList = styled.div`
  margin-left: auto;
`

export const Bottom = styled.div`
  width: 100%;
  color: ${Colors.white};
  display: flex;
  flex-direction: row;
  padding: 0 40px;
  box-sizing: border-box;
  margin-bottom: 50px;
`

export const Stats = styled.div`
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px solid ${Colors.white200} !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:last-of-type {
    border-right: none !important;
    margin-right: 0;
  }
`
export const StatsValue = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
`
export const StatsName = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`
export const Form = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Menu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid ${Colors.white100};
  border-bottom: 1px solid ${Colors.white100};
  margin-bottom: 40px;
  padding: 24px 40px;
  box-sizing: border-box;
`

export const MenuListe = styled.ul`
  margin: 0;
  padding: 0;
`

export const MenuListeItem = styled.li`
  list-style: none;
  float: left;
  color:  ${props => props.selected ? Colors.primary : Colors.white};
  margin-right: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  &:last-of-type {
    margin-right: 0;
  }
`


export const Select = styled(FormSelect)`
  height: 40px;
  min-height: 40px;
  padding-left: ${props => props.hasicon ? "45px" : "17px"};

    .ant-select-selector {
        min-height: 40px !important;
    }
`
