import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

// Redux
import { setCloseDisable } from '@redux/slices/drawerSlice'
import store from '@redux/store'

export default async function deletePodcaps({ id }) {

    store.dispatch(setCloseDisable(true))

    const parameters = `?podcapsId=${id}`

    const response = await apiCall({
        config: API.PODCAPS.DELETE,
        parameters: parameters,
    })

    if (response) {
        store.dispatch(setCloseDisable(false))
        return response
    } else {
        store.dispatch(setCloseDisable(false))
        return null
    }

}
