import DashboardView from "@views/DashboardView/DashboardView"
import PartnersView from "@views/PartnersView/PartnersView"


export const mainRoutes = [
    {
        element: <DashboardView />,
        props: {
            index: true
        }
    },
    {
        path: "partners",
        element: <PartnersView />,
        props: {}
    },
    // {
    //     path: "podcaps",
    //     element: <PodcapsView />,
    //     props: {}
    // },
    // {
    //     path: "podcast",
    //     element: <ShowsView />,
    //     props: {}
    // },
    // {
    //     path: "complaint",
    //     element: <ComplaintView />,
    //     props: {}
    // },
    // {
    //     path: "complaint/*",
    //     element: <ComplaintView />,
    //     props: {}
    // },
    // {
    //     path: "vomoji/*",
    //     element: <VomojiView />,
    //     props: {}
    // },
    // {
    //     path: "rss/*",
    //     element: <RssView />,
    //     props: {}
    // },
    // {
    //     path: "verification/*",
    //     element: <VerificationView />,
    //     props: {}
    // },
]