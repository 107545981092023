import { ACCOUNT_TYPE } from '@common/Enums'
import moment from 'moment'
import { Container, Key, Row, Value } from './DetailStyle'

export default function Detail({
    userData
}) {

    const accountType = () => {
        switch (userData?.AccountType) {
            case ACCOUNT_TYPE.NORMAL:
                return "Normal"

            case ACCOUNT_TYPE.NOT_CLAIMED:
                return "Onaylanmamış"

            case ACCOUNT_TYPE.VERIFIED:
                return "Onaylanmış"

            default:
                break
        }
    }

    // const registerType = () => {
    //     switch (userData?.RegisterType) {
    //         case REGISTER_TYPE.APPLE:
    //             return "Apple"

    //         case REGISTER_TYPE.FACEBOOK:
    //             return "Facebook"

    //         case REGISTER_TYPE.GOOGLE:
    //             return "Google"

    //         case REGISTER_TYPE.PHONE:
    //             return "GSM"

    //         default:
    //             break
    //     }
    // }

    return (
        <Container>
            <Row>
                <Key>
                    Tarih
                </Key>
                <Value>
                    {moment(userData?.CreatedAt).format("DD/MM/YY - hh:mm")}
                </Value>
            </Row>

            <Row>
                <Key>
                    Hesap Durumu
                </Key>
                <Value>
                    {userData?.IsDeactive ? "Deaktif" : "Aktif"}
                </Value>
            </Row>

            <Row>
                <Key>
                    Hesap Türü
                </Key>
                <Value>
                    {accountType()}
                </Value>
            </Row>

            <Row>
                <Key>
                    Dil
                </Key>
                <Value>
                    {userData?.Language === "TR" ? "Türkçe" : "İngilizce"}
                </Value>
            </Row>

            {
                userData?.Email &&
                <Row>
                    <Key>
                        Email
                    </Key>
                    <Value>
                        {userData?.Email}
                    </Value>
                </Row>
            }
            {/* 
            <Row>
                <Key>
                    Doğum Tarihi
                </Key>
                <Value>
                    {moment(userData?.DateOfBirth).format("DD/MM/YYYY")}
                </Value>
            </Row> */}
        </Container>
    )
}
