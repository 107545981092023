// Style
import { BackButton, Container, Title } from './HeaderStyle'

// Enums
import Colors from '@common/Colors'

// Redux
import { clearDrawerSlice } from '@redux/slices/drawerSlice'
import { useDispatch } from 'react-redux'

// Components
import Icon from '@common/components/Icon'

export default function Header() {

    const dispatch = useDispatch()


    const handleOnClose = () => {
        dispatch(clearDrawerSlice())
    }

    return (
        <Container>
            <Icon
                name={"plus-circle-outline"}
                width="24"
                height="24"
                color={Colors.white}
            />

            <Title>
                Yeni Podcaps Oluştur
            </Title>

            <BackButton onClick={() => handleOnClose()}>
                <Icon
                    name={"x-outline"}
                    width="24"
                    height="24"
                    color={Colors.white}
                />
            </BackButton>
        </Container>
    )
}
