
import styled from "styled-components"

import { DashboardBox } from "@common/style"

export const Container = styled(DashboardBox)``

export const Text = styled.div`
color: white;
`

export const Title = styled(Text)`
font-size: 24px;
line-height: 36px;
font-weight: 600;
`

export const Description = styled(Text)`
font-size: 12px;
line-height: 18px;
font-weight: 400;
`

export const Count = styled(Text)`
font-size: 65px;
line-height: 97.5px;
font-weight: 600;
`