import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import DetailContent from "./detail/DetailContent/DetailContent"

export default function VerificationDetailDrawer() {

    const drawerProps = useSelector(state => state.drawer.drawerProps)

    const [userData, setUserData] = useState(null)

    useEffect(() => {
        if (drawerProps?.data) {
            setUserData(drawerProps?.data)
        }
    }, [drawerProps])

    return (
        <DetailContent
            userData={userData}
        />
    )
}
