import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function getTeamLeadSearchCount({ text }) {

    const parameters = `?search=${text ?? ""}`

    const response = await apiCall({
        config: API.USER.GET_TEAM_LEAD_SEARCH_COUNT,
        parameters: parameters
    })

    if (response) {
        return response
    } else {
        return null
    }

}
