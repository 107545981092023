import Form from "../Form/Form"
import Header from "../Header/Header"
import { Container } from "./CreateContentStyle"

export default function CreateContent({
    propsData,
    setIsItemCreate
}) {

    return (
        <Container>
            <Header
                setIsItemCreate={setIsItemCreate}
            />

            <Form
                propsData={propsData}
            />
        </Container>
    )
}
