import { ReactComponent as ArrowLeftOutline } from "@icons/arrow-left-outline.svg"
import { ReactComponent as ArrowRightOutline } from "@icons/arrow-right-outline.svg"
import { ReactComponent as AtOutline } from "@icons/at-outline.svg"
import { ReactComponent as BlockOutline } from "@icons/block-outline.svg"
import { ReactComponent as BrowserOutline } from "@icons/browser-outline.svg"
import { ReactComponent as CalendarOutline } from "@icons/calendar-outline.svg"
import { ReactComponent as ChatVoiceOutline } from "@icons/chat-voice-outline.svg"
import { ReactComponent as ChatVoiceSolid } from "@icons/chat-voice-solid.svg"
import { ReactComponent as CheckOutline } from "@icons/check-outline.svg"
import { ReactComponent as ComplainOutline } from "@icons/complain-outline.svg"
import { ReactComponent as ComplainSolid } from "@icons/complain-solid.svg"
import { ReactComponent as DocumentOutline } from "@icons/document-outline.svg"
import { ReactComponent as EmailOutline } from "@icons/email-outline.svg"
import { ReactComponent as EmailSolid } from "@icons/email-solid.svg"
import { ReactComponent as EyeOutline } from "@icons/eye-outline.svg"
import { ReactComponent as GenderOutline } from "@icons/gender-outline.svg"
import { ReactComponent as GridCirclesOutline } from "@icons/grid-circles-outline.svg"
import { ReactComponent as HashOutline } from "@icons/hash-outline.svg"
import { ReactComponent as HomeOutline } from "@icons/home-outline.svg"
import { ReactComponent as HomeSolid } from "@icons/home-solid.svg"
import { ReactComponent as HourglassOutline } from "@icons/hourglass-outline.svg"
import { ReactComponent as LinkOutline } from "@icons/link-outline.svg"
import { ReactComponent as LoadingOutline } from "@icons/loading-outline.svg"
import { ReactComponent as LockOutline } from "@icons/lock-outline.svg"
import { ReactComponent as LoginOutline } from "@icons/login-outline.svg"
import { ReactComponent as NavDownOutline } from "@icons/nav-down-outline.svg"
import { ReactComponent as NavLeftOutline } from "@icons/nav-left-outline.svg"
import { ReactComponent as NavRightOutline } from "@icons/nav-right-outline.svg"
import { ReactComponent as NoteOutline } from "@icons/note-outline.svg"
import { ReactComponent as PauseOutline } from "@icons/pause-outline.svg"
import { ReactComponent as PenOutline } from "@icons/pen-outline.svg"
import { ReactComponent as PhoneOutline } from "@icons/phone-outline.svg"
import { ReactComponent as PlayCircleOutline } from "@icons/play-circle-outline.svg"
import { ReactComponent as PlaySolid } from "@icons/play-solid.svg"
import { ReactComponent as PlusCircleOutline } from "@icons/plus-circle-outline.svg"
import { ReactComponent as PodcapsOutline } from "@icons/podcaps-outline.svg"
import { ReactComponent as PodcapsSolid } from "@icons/podcaps-solid.svg"
import { ReactComponent as PodcastOutline } from "@icons/podcast-outline.svg"
import { ReactComponent as PodcastSolid } from "@icons/podcast-solid.svg"
import { ReactComponent as PodearSolid } from "@icons/podear-solid.svg"
import { ReactComponent as SearchOutline } from "@icons/search-outline.svg"
import { ReactComponent as ShowOutline } from "@icons/show-outline.svg"
import { ReactComponent as ShowSolid } from "@icons/show-solid.svg"
import { ReactComponent as TagUserOutline } from "@icons/tag-user-outline.svg"
import { ReactComponent as TextSpeechOutline } from "@icons/text-speech-outline.svg"
import { ReactComponent as TransactionOutline } from "@icons/transaction-outline.svg"
import { ReactComponent as TranslateOutline } from "@icons/translate-outline.svg"
import { ReactComponent as TrashOutline } from "@icons/trash-outline.svg"
import { ReactComponent as UploadOutline } from "@icons/upload-outline.svg"
import { ReactComponent as UserOutline } from "@icons/user-outline.svg"
import { ReactComponent as UsersOutline } from "@icons/users-outline.svg"
import { ReactComponent as UsersSolid } from "@icons/users-solid.svg"
import { ReactComponent as VerifiedOutline } from "@icons/verified-outline.svg"
import { ReactComponent as VerifiedSolid } from "@icons/verified-solid.svg"
import { ReactComponent as XOutline } from "@icons/x-outline.svg"


export default function Icon({ width = "24", height = "24", name = "", color = "white" }) {

    const icons = {
        'email-outline': <EmailOutline width={width} height={height} fill={color} />,
        'email-solid': <EmailSolid width={width} height={height} fill={color} />,
        'lock-outline': <LockOutline width={width} height={height} fill={color} />,
        'browser-outline': <BrowserOutline width={width} height={height} fill={color} />,
        'eye-outline': <EyeOutline width={width} height={height} fill={color} />,
        'complain-outline': <ComplainOutline width={width} height={height} fill={color} />,
        'complain-solid': <ComplainSolid width={width} height={height} fill={color} />,
        'home-outline': <HomeOutline width={width} height={height} fill={color} />,
        'home-solid': <HomeSolid width={width} height={height} fill={color} />,
        'podcast-outline': <PodcastOutline width={width} height={height} fill={color} />,
        'podcast-solid': <PodcastSolid width={width} height={height} fill={color} />,
        'podcaps-outline': <PodcapsOutline width={width} height={height} fill={color} />,
        'podcaps-solid': <PodcapsSolid width={width} height={height} fill={color} />,
        'users-outline': <UsersOutline width={width} height={height} fill={color} />,
        'users-solid': <UsersSolid width={width} height={height} fill={color} />,
        'verified-outline': <VerifiedOutline width={width} height={height} fill={color} />,
        'plus-circle-outline': <PlusCircleOutline width={width} height={height} fill={color} />,
        'arrow-right-outline': <ArrowRightOutline width={width} height={height} fill={color} />,
        'nav-right-outline': <NavRightOutline width={width} height={height} fill={color} />,
        'calendar-outline': <CalendarOutline width={width} height={height} fill={color} />,
        'search-outline': <SearchOutline width={width} height={height} fill={color} />,
        'user-outline': <UserOutline width={width} height={height} fill={color} />,
        'x-outline': <XOutline width={width} height={height} fill={color} />,
        'trash-outline': <TrashOutline width={width} height={height} fill={color} />,
        'pen-outline': <PenOutline width={width} height={height} fill={color} />,
        'nav-left-outline': <NavLeftOutline width={width} height={height} fill={color} />,
        'podear-solid': <PodearSolid width={width} height={height} fill={color} />,
        'at-outline': <AtOutline width={width} height={height} fill={color} />,
        'phone-outline': <PhoneOutline width={width} height={height} fill={color} />,
        'gender-outline': <GenderOutline width={width} height={height} fill={color} />,
        'nav-down-outline': <NavDownOutline width={width} height={height} fill={color} />,
        'login-outline': <LoginOutline width={width} height={height} fill={color} />,
        'play-circle-outline': <PlayCircleOutline width={width} height={height} fill={color} />,
        'upload-outline': <UploadOutline width={width} height={height} fill={color} />,
        'document-outline': <DocumentOutline width={width} height={height} fill={color} />,
        'link-outline': <LinkOutline width={width} height={height} fill={color} />,
        'hash-outline': <HashOutline width={width} height={height} fill={color} />,
        'tag-user-outline': <TagUserOutline width={width} height={height} fill={color} />,
        'grid-circles-outline': <GridCirclesOutline width={width} height={height} fill={color} />,
        'hourglass-outline': <HourglassOutline width={width} height={height} fill={color} />,
        'show-outline': <ShowOutline width={width} height={height} fill={color} />,
        'note-outline': <NoteOutline width={width} height={height} fill={color} />,
        'translate-outline': <TranslateOutline width={width} height={height} fill={color} />,
        'play-solid': <PlaySolid width={width} height={height} fill={color} />,
        'pause-outline': <PauseOutline width={width} height={height} fill={color} />,
        'chat-voice-solid': <ChatVoiceSolid width={width} height={height} fill={color} />,
        'chat-voice-outline': <ChatVoiceOutline width={width} height={height} fill={color} />,
        'block-outline': <BlockOutline width={width} height={height} fill={color} />,
        'transaction-outline': <TransactionOutline width={width} height={height} fill={color} />,
        'text-speech-outline': <TextSpeechOutline width={width} height={height} fill={color} />,
        'verified-solid': <VerifiedSolid width={width} height={height} fill={color} />,
        'show-solid': <ShowSolid width={width} height={height} fill={color} />,
        'check-outline': <CheckOutline width={width} height={height} fill={color} />,
        'loading-outline': <LoadingOutline width={width} height={height} fill={color} />,
        'arrow-left-outline': <ArrowLeftOutline width={width} height={height} fill={color} />,
    }

    return (
        icons[name] ?? null
    )
}