import { useEffect, useState } from "react"

// Style
import { BigInput, Container, EmptyImage, FormIcon, FormItem, FormTopIcon, Image, ImageBadge, ImageContainer, Input, Select } from "./FormStyle"

// Helpers

// Color
import Colors from "@common/Colors"

// Icon
import Icon from "@common/components/Icon"

// Formik
import getCategory from "@common/api/category/getCategory"
import putUpdateShow from "@common/api/show/putUpdateShow"
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import { clearDrawerSlice } from "@redux/slices/drawerSlice"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { notification } from "antd"

const { Option } = Select

export default function Form({
    showData
}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()


    const [image, setImage] = useState(null)
    const [imageToShow, setImageToShow] = useState(null)
    const [user, setUser] = useState(null)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [categories, setCategories] = useState([])
    const [categoriesArray, setCategoriesArray] = useState([])
    const [language, setLanguage] = useState(null)
    const [initialCategoriesArray, setInitialCategoriesArray] = useState([])
    const [submitClicked, setSubmitClicked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        getInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setInitialData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showData])

    const setInitialData = async () => {
        if (showData?.ImageUrl) {
            const response = await fetch(showData?.ImageUrl, { mode: "no-cors" })
            const data = await response.blob()
            const ext = showData?.ImageUrl.split(".").pop()
            const filename = showData?.ImageUrl.split("/").pop()
            const metadata = { type: `video/${ext}` }
            const newFile = new File([data], filename, metadata)
            setImage(newFile)
            setImageToShow(showData?.ImageUrl)
        }
        if (showData?.User) {
            setUser(JSON.stringify(showData?.User))
        }
        if (showData?.Title) {
            setTitle(showData?.Title)
        }
        if (showData?.Description) {
            setDescription(showData?.Description)
        }
        if (showData?.Categories) {
            setCategories(showData?.Categories)
        }
        if (showData?.Language) {
            setLanguage(showData?.Language)
        }
    }

    const getInitialData = async () => {
        const categoryResponse = await getCategory()

        if (categoryResponse?.responseJson) {
            setCategoriesArray(categoryResponse?.responseJson)
            setInitialCategoriesArray(categoryResponse?.responseJson)
        }
    }


    const handleOnImageSelectClick = () => {
        document.getElementById('image').click()
    }

    const onImageSelect = event => {
        setImage(event.target.files[0])

        if (event.target.files && event.target.files[0]) {
            setImageToShow(URL.createObjectURL(event.target.files[0]))
        }
    }


    const handleOnCategoriesSearch = (e) => {
        if (e?.length > 0) {
            const newData = categoriesArray?.filter(cat => cat?.Text?.includes(e))

            setCategoriesArray(newData)
        } else {
            setCategoriesArray(initialCategoriesArray)
        }
    }

    const handleOnSubmit = async () => {
        setSubmitClicked(true)

        if (image?.name && title?.length > 0 && user && description?.length > 0 && categories?.length > 0 && language) {
            setIsLoading(true)
            const response = await putUpdateShow({
                id: showData?.Id,
                image: image,
                userId: JSON.parse(user)?.Id,
                title: title,
                categories: categories,
                description: description,
                language: language,
            })

            if (response) {
                searchParams.set("refresh", Math.floor(Math.random() * 100))
                navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                dispatch(clearDrawerSlice())
                notification.success({
                    message: 'Başarılı',
                    description:
                        'Show başarıyla güncellendi.',
                });
            } else {
                setIsLoading(false)
                notification.error({
                    message: 'Hata',
                    description:
                        'Show güncellenirken hata oluştu.',
                });
            }
        }
    }



    return (
        <Container>
            <ImageContainer>
                {
                    imageToShow ?
                        <Image src={imageToShow} />
                        :
                        <EmptyImage errors={submitClicked} />
                }

                <ImageBadge onClick={() => handleOnImageSelectClick()}>
                    <Icon
                        name="plus-circle-outline"
                        width="20"
                        height="20"
                        color={Colors.background}
                    />
                </ImageBadge>
            </ImageContainer>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="show-outline"
                        width="20"
                        height="20"
                        color={
                            title?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="duration"
                    placeholder="Şov Adı"
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    value={title}
                    errors={((!title || title === "") && submitClicked).toString()}
                />
            </FormItem>

            {/* <FormItem>
                <FormIcon>
                    <Icon
                        name="user-outline"
                        width="20"
                        height="20"
                        color={
                            user
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>


                <Select
                    showSearch
                    placeholder={"Hesap"}
                    onChange={(val) => setUser(val)}
                    onSearch={(e) => handleOnUserSearch(e)}
                    value={JSON.parse(user)?.UserName}
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                    errors={(user === null && submitClicked).toString()}
                >
                    {
                        userArray?.map((user) => {
                            return (
                                <Option
                                    value={JSON.stringify(user)}
                                >
                                    {user?.UserName}
                                </Option>
                            )
                        })
                    }
                </Select>
            </FormItem> */}

            <FormItem>
                <FormTopIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            description?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormTopIcon>
                <BigInput
                    type="text"
                    name="user"
                    rows={3}
                    placeholder="Podcaps Açıklaması"
                    onChange={(e) => setDescription(e.currentTarget.value)}
                    value={description}
                    errors={((!description || description === "") && submitClicked).toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="grid-circles-outline"
                        width="20"
                        height="20"
                        color={
                            categories?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>

                <Select
                    placeholder={"Kategoriler"}
                    mode="tags"
                    onChange={(val) => setCategories(val)}
                    onSearch={(e) => handleOnCategoriesSearch(e)}
                    value={categories}
                    filterOption={false}

                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                    errors={(!(categories?.length > 0) && submitClicked).toString()}
                >
                    {
                        categoriesArray?.map((category, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={category?.Id}
                                >
                                    {category?.Text}
                                </Option>
                            )
                        })
                    }
                </Select>
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="translate-outline"
                        width="20"
                        height="20"
                        color={
                            language
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>


                <Select
                    placeholder={"Dil"}
                    onChange={(val) => setLanguage(val)}
                    value={language}
                    suffixIcon={
                        <Icon
                            name="nav-down-outline"
                            width="20"
                            height="20"
                            color={Colors.white400}
                        />
                    }
                    options={[
                        {
                            value: "TR",
                            label: "TR"
                        },
                        {
                            value: "EN",
                            label: "EN"
                        },
                    ]}
                    errors={(!(language?.length > 0) && submitClicked).toString()}

                />
            </FormItem>

            <PrimaryButton type={"button"} onClick={() => handleOnSubmit()} name="Kaydet" icon="plus-outline" loading={isLoading} />

            <input id="image" style={{ visibility: "hidden" }} type="file" accept="image/*" onChange={onImageSelect} />
        </Container>
    )
}
