import Form from "./Form/Form"
import Header from "./Header/Header"
import { Container } from "./PartnerCreateDrawerStyle"

export default function PartnerCreateDrawer() {

    return (
        <Container>
            <Header />

            <Form />
        </Container>
    )
}
