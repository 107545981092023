import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function getByCategoryId({ type, categoryId = "" }) {

    const parameters = `?type=${type}&categoryId=${categoryId}`

    const response = await apiCall({
        config: API.VOICE.GET_BY_CATEGORY_ID,
        parameters: parameters,
    })

    if (response) {
        return response
    } else {
        return null
    }

}
