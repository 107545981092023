import deleteVoice from '@common/api/voice/deleteVoice'
import updateVoiceOrder from '@common/api/voice/updateVoiceOrder'
import Colors from '@common/Colors'
import { SecondaryButton } from '@common/components/buttons/SecondaryButton/SecondaryButton'
import Icon from '@common/components/Icon'
import { clearDrawerSlice } from '@redux/slices/drawerSlice'
import { notification } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ButtonControl, ButtonDelete, Container, Controls, Duration, Item, Row, Title } from './DetailStyle'


var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)




export default function Detail({
    items,
    setIsItemEdit,
    setIsItemEditData,
    setIsItemCategoryChange
}) {
    const dispatch = useDispatch()
    const [playId, setPlayId] = useState(null)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()
    const [data, setData] = useState([])

    const convertDuration = (value) => {
        return dayjs.duration(value, 's').format('mm:ss')
    }

    const handleOnCategoryChange = (value) => {
        setIsItemCategoryChange(true)
        setIsItemEditData(value)
    }

    const handleOnEdit = (value) => {
        setIsItemEdit(true)
        setIsItemEditData(value)
    }

    const handleOnDelete = async (id) => {
        const response = await deleteVoice({ id: id })
        if (response) {
            searchParams.set("refresh", Math.floor(Math.random() * 100))
            navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
            dispatch(clearDrawerSlice())
            notification.success({
                message: 'Başarılı',
                description:
                    'Vomoji başarıyla silindi.',
            })
        } else {
            notification.error({
                message: 'Hata',
                description:
                    'Vomoji silinirken hata oluştu.',
            })
        }
    }

    const titleLanguage = (value) => {
        const index = value.findIndex(item => item.Language === "TR")
        return value[index].Text

    }

    useEffect(() => {
        if (items) {
            setData(items)
        }
    }, [items])


    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return
        var updatedList = [...data]
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
        setData(updatedList)
    }

    const handleOnSaveOrder = async () => {
        const order = data.map(item => item.Id)

        const response = await updateVoiceOrder({ requestBody: { data: order } })

        if (response) {
            notification.success({
                message: 'Başarılı',
                description:
                    'Vomoji sıralama başarıyla güncellendi.',
            })
        } else {
            notification.error({
                message: 'Hata',
                description:
                    'Vomoji sıralama güncellenirken hata oluştu.',
            })
        }
    }

    return (
        <Container>
            {
                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                        {(provided) => (
                            <div
                                className="list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {data.map((item, index) => (
                                    <Draggable key={item.Id} draggableId={item.Id} index={index}>
                                        {(provided) => (
                                            <div
                                                className="drag-item-container"
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                <Item
                                                >
                                                    <ButtonControl onClick={() => setPlayId(current => current === item?.Id ? null : item?.Id)}>
                                                        <ReactPlayer width={0} height={0} loop={true} playing={playId === item?.Id} url={item?.AndroidUrl} />
                                                        {
                                                            item?.Id === playId ?
                                                                <Icon
                                                                    name="pause-outline"
                                                                    width="20"
                                                                    height="20"
                                                                    color={Colors.white}
                                                                /> : <Icon
                                                                    name="play-solid"
                                                                    width="20"
                                                                    height="20"
                                                                    color={Colors.white}
                                                                />
                                                        }

                                                    </ButtonControl>
                                                    <Row>
                                                        <Title>
                                                            {titleLanguage(item?.TitleLanguageDetail)}
                                                        </Title>
                                                        <Duration>
                                                            {convertDuration(item?.Duration)}
                                                        </Duration>
                                                    </Row>
                                                    <Controls>
                                                        <ButtonDelete onClick={() => handleOnCategoryChange(item)}>
                                                            <Icon
                                                                name="transaction-outline"
                                                                width="20"
                                                                height="20"
                                                                color={Colors.white}
                                                            />
                                                        </ButtonDelete>

                                                        <ButtonDelete onClick={() => handleOnEdit(item)}>
                                                            <Icon
                                                                name="pen-outline"
                                                                width="20"
                                                                height="20"
                                                                color={Colors.white}
                                                            />
                                                        </ButtonDelete>

                                                        <ButtonDelete onClick={() => handleOnDelete(item?.Id)}>
                                                            <Icon
                                                                name="trash-outline"
                                                                width="20"
                                                                height="20"
                                                                color={Colors.white}
                                                            />
                                                        </ButtonDelete>
                                                    </Controls>

                                                </Item>
                                            </div>

                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
            {
                data.length > 0 &&
                <SecondaryButton
                    name={"Sıralamayı Kaydet"}
                    onClick={handleOnSaveOrder}
                    fullWidth
                />
            }

        </Container>
    )
}

