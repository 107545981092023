import Form from "../Form/Form"
import Header from "../Header/Header"
import { Container } from "./EditContentStyle"

export default function EditContent({
    setIsEdit,
    showData
}) {
    return (
        <Container>
            <Header setIsEdit={setIsEdit} />

            <Form
                showData={showData}
            />

        </Container>
    )
}
