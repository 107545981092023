import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedTeamLead: null,

}

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {

        //!Clear user Slice
        clearUserSlice: () => initialState,

        setSelectedTeamLead: (state, action) => {
            state.selectedTeamLead = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    clearUserSlice,
    setSelectedTeamLead,
} = userSlice.actions

export default userSlice.reducer
