import Colors from "@common/Colors"
import styled from "styled-components"

export const Button = styled.button`
    background:${(props) => props?.disabled ? Colors.primary600 : Colors.primaryGradient};
    display: flex;
    text-decoration: none;
    padding: 12px 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color:${Colors.white};
    border: none;
    width: ${(props) => props?.fullWidth ? "100%" : undefined};
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 48px;
    gap: 5px;
    cursor: pointer;
    svg{
        margin-right: 5px;
    }
`
