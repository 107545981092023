import CreateContent from "./CreateContent/CreateContent"

export default function PodcastCreateDrawer({
    showData,
    setIsPodcastCreate
}) {
    return (
        <CreateContent
            showData={showData}
            setIsPodcastCreate={setIsPodcastCreate}
        />
    )
}
