import Colors from '@common/Colors'
import { Header } from '@common/components/Header/Header'
import Icon from '@common/components/Icon'
import { Loading } from '@common/components/Loading/Loading'
import { Pagination, Table } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { Container, ShowTableTitleText, TableContainer } from "./PartnersViewStyle"

//Api
import getDashboardAll from '@common/api/dashboard/getDashboardAll'
import getDashboardByUserId from '@common/api/dashboard/getDashboardByUserId'
import getTeamById from '@common/api/user/getTeamById'
import getTeamLead from '@common/api/user/getTeamLead'
import getTeamLeadSearchCount from '@common/api/user/getTeamLeadSearchCount'
import getTeamSearchCount from '@common/api/user/getTeamSearchCount'
import { DRAWER_CONTENT } from '@common/Enums'
import { setDrawerContent, setDrawerVisibility } from '@redux/slices/drawerSlice'
import { setSelectedTeamLead } from '@redux/slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'

const { Column } = Table
const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
        return <a>Previous</a>// eslint-disable-line
    }
    if (type === 'next') {
        return <a>Next</a>// eslint-disable-line
    }
    return originalElement
}

export default function PartnersView() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    const selectedTeamLead = useSelector(state => state.user.selectedTeamLead)

    const [data, setData] = useState([])
    const [selectedUserId, setSelectedUserId] = useState()
    const [headerCounts, setHeaderCounts] = useState([])
    const [paginationCount, setPaginationCount] = useState(0)
    const [page, setPage] = useState(1)
    const [dataLoading, setDataLoading] = useState(true)
    const [paginationLoading, setPaginationLoading] = useState(true)
    const take = 15

    useEffect(() => {
        getCounts({ userId: selectedTeamLead?.id })
    }, [selectedTeamLead])

    useEffect(() => {
        const page = searchParams.get("page")
        const text = searchParams.get("search")
        const userId = searchParams.get("user")

        setSelectedUserId(userId)

        setPage(parseInt(page ?? 1))
        getData({ skip: (parseInt(page ?? 1) - 1) * take, text, userId })
        getPagination({ text, userId })
    }, [searchParams])

    const getCounts = async ({ userId }) => {
        let response = null
        if (userId) {
            response = await getDashboardByUserId({ userId })

            if (response?.responseJson) {
                setHeaderCounts([
                    { name: "Toplam Kazanılan Kullanıcı", value: response?.responseJson?.TotalTrackedUserCount },
                    { name: "Kazandırdığı Kullanıcı", value: response?.responseJson?.MyTrackedUserCount },
                    { name: "Ekibinin Kazandırdığı Kullanıcı", value: response?.responseJson?.TeamTrackedUserCount },
                ])
            }
        } else {
            response = await getDashboardAll()

            if (response?.responseJson) {
                setHeaderCounts([
                    { name: "Toplam Kazanılan Kullanıcı", value: response?.responseJson?.TotalTrackedUserCount },
                ])
            }
        }

    }

    const getData = async ({ text, skip, take, userId }) => {
        setDataLoading(true)
        let response = null
        if (userId) {
            response = await getTeamById({ text, skip, take, userId })
        } else {
            response = await getTeamLead({ text, skip, take })
        }
        if (response?.responseJson) {
            setData(response?.responseJson)
            setDataLoading(false)
        }
    }

    const getPagination = async ({ text, userId }) => {
        setPaginationLoading(true)
        let response = null
        if (userId) {
            response = await getTeamSearchCount({ text, userId })
        } else {
            response = await getTeamLeadSearchCount({ text })
        }
        if (response?.responseJson) {
            setPaginationCount(response?.responseJson)
            setPaginationLoading(false)
        }
    }

    const paginationOnChange = (event) => {
        searchParams.set("page", event)
        navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
    }

    const handleItemNavigate = (value) => {
        dispatch(setSelectedTeamLead({
            id: value?.Id,
            fullname: value?.FirstName + " " + value?.LastName
        }))
        setSelectedUserId(value?.Id)
    }


    const handleHeaderBackButton = (value) => {
        if (selectedUserId) {
            dispatch(setSelectedTeamLead(null))
            navigate({ pathname: value })
        }
    }

    const handleNewPartnerButton = () => {
        dispatch(setDrawerContent(DRAWER_CONTENT.PARTNER_CREATE))
        dispatch(setDrawerVisibility(true))
    }

    return (
        <Container>
            <Header
                title={selectedTeamLead ? selectedTeamLead.fullname : "Partnerler"}
                icon={selectedTeamLead ? "arrow-left-outline" : "users-outline"}
                newButtonTitle="Yeni Partner Oluştur"
                newButtonEvent={handleNewPartnerButton}
                stats={headerCounts}
                search={true}
                datePicker={false}
                selectedUserId={selectedUserId}
                hasBackButton={selectedTeamLead}
                handleBackButton={handleHeaderBackButton}
            />
            {dataLoading && paginationLoading ? <Loading /> :
                <TableContainer>
                    {(data.length > 0 || !dataLoading) &&
                        <>
                            <Table dataSource={data} rowKey="Id" pagination={false}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            if (!selectedUserId) {
                                                handleItemNavigate(record)
                                            }
                                        },
                                    }
                                }}
                            >
                                <Column title="Üyelik Tarihi" dataIndex="CreatedAt" key="CreatedAt"
                                    render={(value) => value ? dayjs(value)
                                        .format('DD/MM/YYYY - HH:MM') : "-"} />
                                <Column title="Ad Soyad" key="Fullname"
                                    render={(_, item) => (
                                        <ShowTableTitleText>
                                            {item?.FirstName + " " + item?.LastName}
                                        </ShowTableTitleText>
                                    )}
                                />
                                <Column title="Kazandırdığı Kişi Sayısı" dataIndex="TrackedUserCount" key="TrackedUserCount" align="center" />
                                {
                                    !selectedUserId &&
                                    <>
                                        <Column title="Ekibin Kazandırdığı Kişi Sayısı" dataIndex="TeamTrackedUserCount" key="TeamTrackedUserCount" align="center" />
                                        <Column title="" key="action"
                                            render={(_, item) => (
                                                <div onClick={() => handleItemNavigate(item)}>
                                                    <Icon
                                                        name={"nav-right-outline"}
                                                        width="20"
                                                        height="20"
                                                        color={Colors.white}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </>
                                }

                            </Table>
                            <Pagination
                                current={page}
                                total={paginationCount}
                                defaultPageSize={take}
                                itemRender={itemRender}
                                showSizeChanger={false}
                                showLessItems={false}
                                showQuickJumper={false}
                                onChange={paginationOnChange}
                            />
                        </>
                    }
                </TableContainer>
            }
        </Container>
    )
}
