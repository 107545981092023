// Style
import { BackButton, Container, Title } from './HeaderStyle'

// Enums
import Colors from '@common/Colors'

// Redux
import { clearDrawerSlice } from '@redux/slices/drawerSlice'
import { useDispatch } from 'react-redux'

// Components
import Icon from '@common/components/Icon'

export default function Header({
    setIsItemCreate
}) {

    const dispatch = useDispatch()

    const handleOnBack = () => {
        setIsItemCreate(false)
    }


    const handleOnClose = () => {
        dispatch(clearDrawerSlice())
    }

    return (
        <Container>
            <BackButton onClick={() => handleOnBack()}>
                <Icon
                    name={"nav-left-outline"}
                    width="24"
                    height="24"
                    color={Colors.white}
                />
            </BackButton>


            <Title>
                Yeni Vomoji Yükle
            </Title>

            <BackButton onClick={() => handleOnClose()}>
                <Icon
                    name={"x-outline"}
                    width="24"
                    height="24"
                    color={Colors.white}
                />
            </BackButton>
        </Container>
    )
}
