import Colors from "@common/Colors"
import styled from "styled-components"

import { FormInput, FormInputIcon, FormInputItem } from "@common/style"

export const FormItem = styled(FormInputItem)``
export const FormIcon = styled(FormInputIcon)``

export const Input = styled(FormInput)`
  height: 56px;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 44px;
`

export const Divider = styled.div`
  width: 1px;
  height: 100px;
  background-color: ${Colors.white200};
  margin: 0px 24px;
`

export const HeaderTitle = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.white};
  margin: 0;
  padding: 0;
  font-weight: 600;
`

export const FormTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 24px;
  color: ${Colors.white};
  margin: 0;
  padding: 0;
  font-weight: 600;
  margin-bottom: 24px;
`

export const Form = styled.form`
  margin-bottom: 24px;
  width: 464px;
  padding: 40px;
  border: 1px solid ${Colors.white200};
  border-radius: 16px;
`
