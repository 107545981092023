
// Api

// Components

import { Container, Count, Description, Title } from './MyTotalGainedUsersStyle'

export default function MyTotalGainedUsers({ dashboardData }) {


    return (
        <Container>
            <Title>
                Kazandığım Kullanıcı
            </Title>

            <Count>
                {dashboardData?.MyTrackedUserCount}
            </Count>

            <Description>
                Kullanıcı
            </Description>
        </Container>
    )
}
