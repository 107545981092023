import Colors from "@common/Colors"
import styled from "styled-components"

export const Button = styled.div`
    opacity:${(props) => props?.disabled ? 0.5 : 1};
    display: flex;
    flex: ${props => props.fullWidth && 1};
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 12px 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color:${Colors.white};
    background-color: ${props => props.isDisable && Colors.white100};
    border: 1px solid ${Colors.white200};
    border-radius: 48px;
    cursor: ${props => props.isDisable ? "default" : "pointer"};
    svg{
        margin-right: 5px;
    }
`
