// Routes
import { mainRoutes } from '@routes/mainRoutes'
import { Route, Routes } from 'react-router-dom'

// Redux
import { Provider } from "react-redux"

// Layout
import AdminLayout from '@layouts/AdminLayout/AdminLayout'

// Views
import LoginView from '@views/LoginView/LoginView'
import NotFoundView from '@views/NotFoundView/NotFoundView'

// Components
import { AuthContextProvider } from '@common/components/auth/AuthContext'
import AuthGuard from '@common/components/auth/AuthGuard'
import store from '@redux/store'

//Locale
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import tr_TR from "antd/lib/locale/tr_TR";
dayjs.locale("tr");


export default function App() {
  return (
    <ConfigProvider locale={tr_TR}>
      <Provider store={store}>
        <AuthContextProvider>
          <Routes>
            <Route path="login" element={<AuthGuard><LoginView /></AuthGuard>} />
            <Route path="/" element={<AuthGuard><AdminLayout /></AuthGuard>}>
              {
                mainRoutes.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route?.path}
                      element={route?.element}
                      {...route.props}
                    />
                  )
                })
              }
            </Route>
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        </AuthContextProvider>
      </Provider>
    </ConfigProvider>

  )
}

