// Style
import { Container, Description, Image, Title, Username } from "./ShowInfoStyle"

export default function ShowInfo({
    showData
}) {

    return (
        <Container>
            {
                showData?.ImageUrl &&
                <Image
                    src={showData?.ImageUrl}
                />
            }

            <Title>
                {showData?.Title}
            </Title>

            <Username>
                {showData?.User?.UserName}
            </Username>

            <Description>
                {showData?.Description}
            </Description>
        </Container>
    )
}
