import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function getTeamById({ text, skip = 0, take = 15, userId }) {

    const parameters = `?skip=${skip ?? 0}&limit=${take ?? 15}&search=${text ?? ""}&id=${userId}`

    const response = await apiCall({
        config: API.USER.GET_TEAM_BY_ID,
        parameters: parameters,
    })

    if (response) {
        return response
    } else {
        return null
    }

}
