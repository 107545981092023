import { useState } from "react"

// Style
import { Container, File, FormIcon, FormItem, FormSuffixIcon, Input } from "./FormStyle"

// Color
import Colors from "@common/Colors"

// Icon
import Icon from "@common/components/Icon"

// Formik
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import { clearDrawerSlice } from "@redux/slices/drawerSlice"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { notification } from "antd"
import uploadVoice from "@common/api/voice/uploadVoice"

export default function Form({
    propsData
}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const [soundMp3, setSoundMp3] = useState(null)
    const [soundM4a, setSoundM4a] = useState(null)

    const [title, setTitle] = useState("")
    const [titleEN, setTitleEN] = useState("")
    const [duration, setDuration] = useState("")

    const [submitClicked, setSubmitClicked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleOnSoundSelectClickMp3 = () => {
        document.getElementById('soundMp3').click()
    }

    const handleOnSoundSelectClickM4a = () => {
        document.getElementById('soundM4a').click()
    }

    const onSoundSelectMp3 = event => {
        setSoundMp3(event.target.files[0])
    }

    const onSoundSelectM4a = event => {
        setSoundM4a(event.target.files[0])
    }

    const handleOnSubmit = async () => {

        setSubmitClicked(true)

        if (soundMp3?.name && soundM4a?.name && title && titleEN) {
            setIsLoading(true)
            const response = await uploadVoice({
                mp3: soundMp3,
                m4a: soundM4a,
                duration: duration,
                categoryId: propsData?.Id,
                type: propsData?.Type,
                title: title,
                titleEN: titleEN,
            })

            if (response) {
                searchParams.set("refresh", Math.floor(Math.random() * 100))
                navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                dispatch(clearDrawerSlice())
                notification.success({
                    message: 'Başarılı',
                    description:
                        'Vomoji başarıyla yüklendi.',
                });
            } else {
                setIsLoading(false)
                notification.error({
                    message: 'Hata',
                    description:
                        'Vomoji yüklenirken hata oluştu.',
                });
            }
        }
    }

    return (
        <Container>
            <FormItem onClick={() => handleOnSoundSelectClickMp3()}>
                <FormIcon>
                    <Icon
                        name="note-outline"
                        width="20"
                        height="20"
                        color={
                            soundMp3?.name
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <File
                    name="Sound"
                    onChange={(val) => setSoundMp3(val)}
                    hasValue={soundMp3?.name}
                    errors={(!soundMp3?.name && submitClicked).toString()}
                >
                    {soundMp3?.name ?? "Ses Dosyası (.mp3)"}
                </File>

                <FormSuffixIcon>
                    <Icon
                        name="upload-outline"
                        width="20"
                        height="20"
                        color={Colors.white400}
                    />
                </FormSuffixIcon>
            </FormItem>

            <FormItem onClick={() => handleOnSoundSelectClickM4a()}>
                <FormIcon>
                    <Icon
                        name="note-outline"
                        width="20"
                        height="20"
                        color={
                            soundM4a?.name
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <File
                    name="Sound2"
                    onChange={(val) => setSoundM4a(val)}
                    hasValue={soundM4a?.name}
                    errors={(!soundM4a?.name && submitClicked).toString()}
                >
                    {soundM4a?.name ?? "Ses Dosyası (.m4a)"}
                </File>

                <FormSuffixIcon>
                    <Icon
                        name="upload-outline"
                        width="20"
                        height="20"
                        color={Colors.white400}
                    />
                </FormSuffixIcon>
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="hourglass-outline"
                        width="20"
                        height="20"
                        color={
                            duration?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="number"
                    name="Duration"
                    placeholder="Duration"
                    onChange={(e) => setDuration(e.currentTarget.value)}
                    value={duration}
                    errors={((!duration || duration === "") && submitClicked).toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            title?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="title"
                    placeholder="Vomoji Adı (TR)"
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    value={title}
                    errors={((!title || title === "") && submitClicked).toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            titleEN?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="titleEN"
                    placeholder="Vomoji Adı (EN)"
                    onChange={(e) => setTitleEN(e.currentTarget.value)}
                    value={titleEN}
                    errors={((!titleEN || titleEN === "") && submitClicked).toString()}
                />
            </FormItem>

            <PrimaryButton type={"button"} onClick={() => handleOnSubmit()} name="Kaydet" icon="plus-outline" loading={isLoading} />

            <input id="soundMp3" style={{ visibility: "hidden" }} type="file" accept=".mp3" onChange={onSoundSelectMp3} />
            <input id="soundM4a" style={{ visibility: "hidden" }} type="file" accept=".m4a" onChange={onSoundSelectM4a} />
        </Container>
    )
}
