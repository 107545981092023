import Colors from "@common/Colors"
import styled from "styled-components"

export const Container = styled.div`
display: flex;
flex: 1;
flex-direction: column;
padding: 0 40px;
gap:15px;
margin-top: 32px;
padding-bottom: 50px;
`

export const Item = styled.div`
display: flex;
flex: 1;
flex-direction: row;
background-color: ${Colors.white100};
padding: 15px 10px;
border-radius: 12px;
gap: 15px;
align-items: center;
margin-bottom: 30;
`

export const Row = styled.div`
display: flex;
flex-direction: column;
flex: 1;
`

export const Image = styled.img`
width: 80px;
height: 80px;
border-radius: 12px;
`

export const Title = styled.div`
display: flex;
flex: 1;
font-size: 16px;
font-family: "Plus Jakarta Sans";
font-weight: 500;
color: ${Colors.white};
`

export const Duration = styled.div`
font-size: 12px;
font-family: "Plus Jakarta Sans";
font-weight: 500;
color: ${Colors.white400};
`

export const ButtonControl = styled.div`
cursor: pointer;
height: 36px;
width: 36px;
background-color: #EC7567;
justify-content: center;
align-items: center;
border-radius:50%;
display: flex;
`

export const ButtonDelete = styled.div`
cursor: pointer;
display: flex;
height: 36px;
width: 36px;
background-color:  ${Colors.white100};
justify-content: center;
align-items: center;
border-radius:50%;
`

export const Controls = styled.div`
display: flex;
flex-direction: row;
gap: 5px;
`