import getByCategoryId from "@common/api/voice/getByCategoryId"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import DetailContent from "./detail/DetailContent/DetailContent"
import EditContent from "./edit/EditContent/EditContent"
import VomojiItemCategoryChangeDrawer from "./vomojiItem/VomojiItemCategoryChangeDrawer/VomojiItemCategoryChangeDrawer"
import VomojiItemCreateDrawer from "./vomojiItem/VomojiItemCreateDrawer/VomojiItemCreateDrawer"
import VomojiItemEditDrawer from "./vomojiItem/VomojiItemEditDrawer/VomojiItemEditDrawer"

export default function VomojiDetailDrawer() {

    const drawerProps = useSelector(state => state.drawer.drawerProps?.vomojiCategory)

    const [isEdit, setIsEdit] = useState(false)
    const [isItemEdit, setIsItemEdit] = useState(false)
    const [isItemCategoryChange, setIsItemCategoryChange] = useState(false)
    const [isItemEditData, setIsItemEditData] = useState()

    const [isItemCreate, setIsItemCreate] = useState(false)
    const [items, setItems] = useState(null)

    useEffect(() => {
        if (drawerProps) {
            const getData = async () => {
                const response = await getByCategoryId({ type: drawerProps?.Type, categoryId: drawerProps?.Id })
                if (response?.responseJson) {
                    setItems(response?.responseJson)
                }
            }

            getData()
        }
    }, [drawerProps])


    return (
        isEdit ?
            <EditContent
                setIsEdit={setIsEdit}
                propsData={drawerProps}
            />
            :
            isItemCreate ?
                <VomojiItemCreateDrawer
                    propsData={drawerProps}
                    setIsItemCreate={setIsItemCreate}
                />
                : isItemEdit ?
                    <VomojiItemEditDrawer
                        setIsItemEdit={setIsItemEdit}
                        isItemEditData={isItemEditData}
                    />
                    : isItemCategoryChange ?
                        <VomojiItemCategoryChangeDrawer
                            setIsItemCategoryChange={setIsItemCategoryChange}
                            isItemEditData={isItemEditData}
                        />
                        :
                        <DetailContent
                            setIsItemEdit={setIsItemEdit}
                            setIsItemCategoryChange={setIsItemCategoryChange}
                            setIsItemEditData={setIsItemEditData}
                            setIsEdit={setIsEdit}
                            setIsItemCreate={setIsItemCreate}
                            propsData={drawerProps}
                            items={items}
                        />

    )
}
