import { useState } from "react"

// Style
import { Container, FormIcon, FormItem, FormRadio, Input } from "./FormStyle"

// Helpers

// Color
import Colors from "@common/Colors"

// Icon
import Icon from "@common/components/Icon"

// Formik
import postCreateRss from "@common/api/rss/postCreateRss"
import postCreateRssPartner from "@common/api/rss/postCreateRssPartner"
import { PrimaryButton } from "@common/components/buttons/PrimaryButton/PrimaryButton"
import { RSS_TYPE } from "@common/Enums"
import { clearDrawerSlice } from "@redux/slices/drawerSlice"
import { notification } from "antd"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

export default function Form() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const [title, setTitle] = useState("")
    const [url, setUrl] = useState("")
    const [language, setLanguage] = useState("TR")
    const [type, setType] = useState("1")


    const [submitClicked, setSubmitClicked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    const onLanguageChange = e => {
        setLanguage(e.target.value)
    }

    const onTypeChange = e => {
        setType(e.target.value)
    }


    const handleOnSubmit = async () => {
        setSubmitClicked(true)

        if (title?.length > 0 && url?.length > 0) {
            setIsLoading(true)
            let response = null
            if (type === RSS_TYPE.PARTNER) {
                response = await postCreateRssPartner({
                    requestBody: {
                        Name: title,
                        RssUrl: url,
                        Language: language
                    }
                })
            } else {
                response = await postCreateRss({
                    requestBody: {
                        Name: title,
                        RssUrl: url,
                        Language: language
                    }
                })

            }


            if (response) {
                searchParams.set("refresh", Math.floor(Math.random() * 100))
                navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
                dispatch(clearDrawerSlice())
                notification.success({
                    message: 'Başarılı',
                    description:
                        'Rss başarıyla yüklendi.',
                })
            } else {
                setIsLoading(false)
                notification.error({
                    message: 'Hata',
                    description:
                        'Rss yüklenirken hata oluştu.',
                })
            }
        }
    }



    return (
        <Container>
            <FormRadio>
                <div>
                    <input
                        type="radio"
                        name="type"
                        value="1"
                        id="genel"
                        checked={type === "1"}
                        onChange={onTypeChange}
                    />
                    <label htmlFor="genel">Genel</label>
                </div>
                <div>
                    <input
                        type="radio"
                        name="type"
                        value="2"
                        id="partner"
                        checked={type === "2"}
                        onChange={onTypeChange}
                    />
                    <label htmlFor="partner">Partner</label>
                </div>
            </FormRadio>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            title?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="title"
                    placeholder={`Rss Adı (${language})`}
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    value={title}
                    errors={((!title || title === "") && submitClicked).toString()}
                />
            </FormItem>

            <FormItem>
                <FormIcon>
                    <Icon
                        name="document-outline"
                        width="20"
                        height="20"
                        color={
                            url?.length > 0
                                ? Colors.white
                                : Colors.white400
                        }
                    />
                </FormIcon>
                <Input
                    type="text"
                    name="url"
                    placeholder={`Rss Url`}
                    onChange={(e) => setUrl(e.currentTarget.value)}
                    value={url}
                    errors={((!url || url === "") && submitClicked).toString()}
                />
            </FormItem>

            <FormRadio>
                <div>
                    <input
                        type="radio"
                        name="topping"
                        value="TR"
                        id="tr"
                        checked={language === "TR"}
                        onChange={onLanguageChange}
                    />
                    <label htmlFor="tr">Türkçe</label>
                </div>
                <div>
                    <input
                        type="radio"
                        name="language"
                        value="EN"
                        id="en"
                        checked={language === "EN"}
                        onChange={onLanguageChange}
                    />
                    <label htmlFor="en">İngilizce</label>
                </div>
            </FormRadio>


            <PrimaryButton type={"button"} onClick={() => handleOnSubmit()} name="Kaydet" icon="plus-outline" loading={isLoading} />
        </Container>
    )
}
