import { clearDrawerSlice } from '@redux/slices/drawerSlice'
import { useDispatch, useSelector } from "react-redux"
import CommonDrawerContent from './CommonDrawerContent'
import { DrawerContainer } from './CommonDrawerStyle'

export default function CommonDrawer() {

    const dispatch = useDispatch()

    const drawerVisible = useSelector(state => state.drawer.drawerVisible)
    const drawerCloseDisable = useSelector(state => state.drawer.drawerCloseDisable)

    const handleOnClose = () => {
        if (!drawerCloseDisable) {
            dispatch(clearDrawerSlice())
        }
    }

    return (
        <DrawerContainer
            open={drawerVisible}
            contentWrapperStyle={{ width: 500 }}
            destroyOnClose
            closeIcon={false}
            onClose={() => handleOnClose()}
        >
            <CommonDrawerContent />
        </DrawerContainer>
    )
}
