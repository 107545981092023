import deleteUser from '@common/api/user/deleteUser'
import putUserVerified from '@common/api/user/putUserVerified'
import { useUserAuth } from '@common/components/auth/AuthContext'
import { SecondaryButton } from '@common/components/buttons/SecondaryButton/SecondaryButton'
import { clearDrawerSlice } from '@redux/slices/drawerSlice'
import { notification } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Container } from './FooterStyle'

export default function Footer({
    userData,
    setIsEdit,
    setIsReachAccount
}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { user } = useUserAuth()

    const { pathname } = useLocation()

    const [verifyRequested, setVerifyRequested] = useState(false)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)


    const handleOnReachAccount = async () => {
        setIsReachAccount(true)
    }

    const handleOnVerify = async () => {
        setVerifyRequested(true)

        const response = await putUserVerified({ id: userData?.Id, isVerified: !userData?.IsVerified })

        if (response) {
            setVerifyRequested(false)
            dispatch(clearDrawerSlice())
        }
    }

    const handleOnDelete = async (block) => {
        setIsLoadingDelete(true)

        const response = await deleteUser({ id: userData?.Id, block: block })

        if (response) {
            searchParams.set("refresh", Math.floor(Math.random() * 100))
            navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
            dispatch(clearDrawerSlice())
            notification.success({
                message: 'Başarılı',
                description:
                    `Üye başarıyla ${block ? "engellendi" : "silindi"}.`,
            })
            setIsLoadingDelete(false)

        } else {
            notification.error({
                message: 'Hata',
                description:
                    `Üye ${block ? "engellenirken" : "silinirken"} hata oluştu.`,
            })
            setIsLoadingDelete(false)
        }
    }

    // const handleOnEdit = () => {
    //     setIsEdit(true)
    // }

    return (
        <Container>
            {
                (user?.permission?.IsAuthorizeForUserMerge && userData?.AccountType === 2) &&
                <SecondaryButton
                    name={"Hesaba Erişim Sağla"}
                    onClick={() => handleOnReachAccount()}
                    fullWidth
                />
            }
            {
                !(userData?.AccountType === 2) &&
                <SecondaryButton
                    name={userData?.AccountType === 1 ? "Onayı Kaldır" : "Hesabı Onayla"}
                    onClick={handleOnVerify}
                    isDisable={verifyRequested}
                    fullWidth
                />
            }
            {
                user?.permission?.IsAuthorizeForUserBlock &&
                <SecondaryButton
                    name={"Hesabı Engelle"}
                    icon="block-outline"
                    onClick={() => handleOnDelete(true)}
                    fullWidth
                    isDisable={isLoadingDelete}
                />
            }
            {
                user?.permission?.IsAuthorizeForUserDelete &&
                <SecondaryButton
                    name={"Hesabı Sil"}
                    icon="trash-outline"
                    onClick={() => handleOnDelete(false)}
                    fullWidth
                    isDisable={isLoadingDelete}
                />
            }

            {/* <SecondaryButton
                name={"Hesabı Düzenle"}
                icon="pen-outline"
                onClick={handleOnEdit}
                fullWidth
            /> */}
        </Container>
    )
}
