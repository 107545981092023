
// Style
import { Container } from "./DetailContentStyle"

// Api

// Redux

// Components
import Controls from "../Controls/Controls"
import Detail from "../Detail/Detail"
import Header from "../Header/Header"
import ShowInfo from "../ShowInfo/ShowInfo"
import { useRef, useState } from "react"
import getShowById from "@common/api/show/getShowById"


export default function DetailContent({
    setIsEdit,
    setIsPodcastEdit,
    setPodcastToEdit,
    setIsPodcastCreate,
    showData,
    setShowEpisodes,
    showEpisodes
}) {

    const listInnerRef = useRef();
    const [skip, setSkip] = useState(15)
    const [endData, setEndData] = useState(false)
    const [loading, setLoading] = useState(true)

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (Math.round(scrollTop) + Math.round(clientHeight) === scrollHeight) {
                handleLoadMore()
            }
        }
    };

    const handleLoadMore = async () => {
        if (loading && !endData) {
            setLoading(false)
            const response = await getShowById({ showId: showData?.Id, skip: skip })
            if (response?.responseJson) {
                if (response?.responseJson?.Episodes?.length < 15) {
                    setEndData(true)
                }
                setSkip(current => current + 15)
                setShowEpisodes(current => {
                    return [...current, ...response?.responseJson?.Episodes]
                })
                setLoading(true)
            }
        }

    }


    return (
        <Container
            onScroll={onScroll}
            ref={listInnerRef}
        >
            <Header />

            <ShowInfo
                showData={showData}
            />

            <Controls
                showData={showData}
                setIsEdit={setIsEdit}
                setIsPodcastCreate={setIsPodcastCreate}
            />

            <Detail
                showId={showData?.Id}
                showEpisodes={showEpisodes}
                setShowEpisodes={setShowEpisodes}
                setIsPodcastEdit={setIsPodcastEdit}
                setPodcastToEdit={setPodcastToEdit}
            />
        </Container>
    )
}
