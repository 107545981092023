import deleteShow from '@common/api/show/deleteShow'
import Colors from '@common/Colors'
import Icon from '@common/components/Icon'
import { clearDrawerSlice } from '@redux/slices/drawerSlice'
import { useDispatch } from 'react-redux'
import { Button, Container } from './ControlsStyle'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { notification } from 'antd'

export default function Controls({
    showData,
    setIsEdit,
    setIsPodcastCreate,
}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()

    const handleOnAddPodcast = () => {
        setIsPodcastCreate(true)
    }

    const handleOnEditShow = () => {
        setIsEdit(true)
    }

    const handleOnDeleteShow = async () => {

        const response = await deleteShow({ id: showData?.Id })

        if (response) {
            searchParams.set("refresh", Math.floor(Math.random() * 100))
            navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
            dispatch(clearDrawerSlice())
            notification.success({
                message: 'Başarılı',
                description:
                    'Show başarıyla silindi.',
            });
        } else {
            notification.error({
                message: 'Hata',
                description:
                    'Show silinirken hata oluştu.',
            });
        }
    }


    return (
        <Container>
            <Button onClick={() => handleOnAddPodcast()}>
                <Icon
                    name="plus-circle-outline"
                    width="24"
                    height="24"
                    color={Colors.white}
                />
            </Button>

            <Button onClick={() => handleOnEditShow()}>
                <Icon
                    name="pen-outline"
                    width="24"
                    height="24"
                    color={Colors.white}
                />
            </Button>

            <Button onClick={() => handleOnDeleteShow()}>
                <Icon
                    name="trash-outline"
                    width="24"
                    height="24"
                    color={Colors.white}
                />
            </Button>
        </Container>
    )
}
