import { useEffect, useState } from 'react'

// Components
import getTeam from '@common/api/user/getTeam'
import getTeamSearchCount from '@common/api/user/getTeamSearchCount'
import { Loading } from '@common/components/Loading/Loading'
import { Pagination, Table } from 'antd'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Container, ShowTableTitleText, TableContainer, Title } from './TeamTableStyle'

const { Column } = Table

const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
        return <a>Previous</a>// eslint-disable-line
    }
    if (type === 'next') {
        return <a>Next</a>// eslint-disable-line
    }
    return originalElement
}

export default function TeamTable() {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()


    const [data, setData] = useState([])
    const [paginationCount, setPaginationCount] = useState(0)
    const [page, setPage] = useState(1)
    const [dataLoading, setDataLoading] = useState(true)
    const [paginationLoading, setPaginationLoading] = useState(true)
    const take = 15



    useEffect(() => {
        const page = searchParams.get("page")

        setPage(parseInt(page ?? 1))
        getData({ skip: (parseInt(page ?? 1) - 1) * take })
        getPagination()
    }, [searchParams])

    const getData = async ({ skip, take }) => {
        setDataLoading(true)
        const response = await getTeam({ skip, take })
        if (response?.responseJson) {
            setData(response?.responseJson)
            setDataLoading(false)
        }
    }

    const getPagination = async () => {
        setPaginationLoading(true)
        const response = await getTeamSearchCount({})
        if (response?.responseJson) {
            setPaginationCount(response?.responseJson)
            setPaginationLoading(false)
        }
    }

    const paginationOnChange = (event) => {
        searchParams.set("page", event)
        navigate({ pathname: pathname, search: `?${searchParams.toString()}` })
    }

    return (
        <Container>
            <Title>
                Ekibim ({data?.length})
            </Title>

            {
                dataLoading && paginationLoading ? <Loading /> :
                    <TableContainer>
                        {(data.length > 0 || !dataLoading) &&
                            <>
                                <Table dataSource={data} rowKey="Id" pagination={false}>
                                    <Column title="Ad Soyad" key="Fullname"
                                        render={(_, item) => (
                                            <ShowTableTitleText>
                                                {item?.FirstName + " " + item?.LastName}
                                            </ShowTableTitleText>
                                        )}
                                    />
                                    <Column title="Kazandırdığı Kullanıcı" dataIndex="TrackedUserCount" key="TrackedUserCount" align="center" />
                                </Table>
                                <Pagination
                                    current={page}
                                    total={paginationCount}
                                    defaultPageSize={take}
                                    itemRender={itemRender}
                                    showSizeChanger={false}
                                    showLessItems={false}
                                    showQuickJumper={false}
                                    onChange={paginationOnChange}
                                />
                            </>
                        }
                    </TableContainer>
            }
        </Container>
    )
}
