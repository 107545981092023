import CategoryChangeContent from "./CategoryChangeContent/CategoryChangeContent"

export default function VomojiItemCategoryChangeDrawer({
    setIsItemCategoryChange,
    isItemEditData
}) {

    return (
        <CategoryChangeContent
            setIsItemCategoryChange={setIsItemCategoryChange}
            isItemEditData={isItemEditData}
        />
    )
}
