import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function getUser() {

    const response = await apiCall({
        config: API.USER.GET,
    })

    if (response) {
        return response
    } else {
        return null
    }

}
