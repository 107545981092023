
import styled from "styled-components"

import { DashboardBox } from "@common/style"

export const Container = styled(DashboardBox)`
align-self: unset;
`

export const Text = styled.div`
color: white;
`

export const Title = styled(Text)`
margin-bottom: 24px;
font-size: 18px;
line-height: 24px;
font-weight: 600;
`

export const Count = styled(Text)`
font-size: 24px;
line-height: 36px;
font-weight: 600;
`

export const Description = styled(Text)`
font-size: 13px;
line-height: 20px;
font-weight: 500;
`