import Form from "../Form/Form"
import Header from "../Header/Header"
import { Container } from "./EditContentStyle"

export default function EditContent({
    podcapsData
}) {

    return (
        <Container>
            <Header />

            <Form
                podcapsData={podcapsData}
            />
        </Container>
    )
}
