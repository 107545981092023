import menuData from "@components/admin/Menu/data"
import { Navigate, useLocation } from "react-router-dom"
import { Loading } from "../Loading/Loading"
import { useUserAuth } from "./AuthContext"


const AuthGuard = ({ children }) => {
    const { user, loadingAuth } = useUserAuth()
    const location = useLocation()

    const checkPermission = (value) => {
        const index = menuData.findIndex(item => item.path === value)
        return menuData[index]?.accountType ? user.AccountType === menuData[index]?.accountType : true
    }

    if (location?.pathname === "/login") {
        if (loadingAuth) {
            return <Loading />
        } else if (user && !loadingAuth) {
            return <Navigate to="/" />
        } else {
            return children
        }
    } else {
        if (loadingAuth) {
            return <Loading />
        } else if (!user && !loadingAuth) {
            return <Navigate to="/login" />
        } else {
            if (!checkPermission(location?.pathname)) {
                if (user.AccountType === 2) {
                    return <Navigate to="/" />
                } else if (user.AccountType === 1) {
                    return <Navigate to="/partners" />
                } else {
                    return <Navigate to="/" />
                }
            } else {
                return children
            }

        }
    }

}

export default AuthGuard