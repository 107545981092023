
// Api

// Components

import { Container, Count, Description, Title } from './TeamTotalGainedUsersStyle'

export default function TeamTotalGainedUsers({ dashboardData }) {


    return (
        <Container>
            <Title>
                Ekibimim Kazandırdığı Kullanıcı
            </Title>

            <Count>
                {dashboardData?.TeamTrackedUserCount}
            </Count>

            <Description>
                Kullanıcı
            </Description>
        </Container>
    )
}
