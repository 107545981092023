// Helpers
import fetchOptions from '@helpers/fetch/fetchOptions'

// Firebase

// Redux
import { setToken } from '@redux/slices/authSlice'
import store from '@redux/store'
import { getAuth } from 'firebase/auth'

export default async function apiCall({
    config,
    requestBody,
    parameters,
    contentType,
}) {
    const state = store.getState()

    // Her Api Callda Token Değişmişmi Diye Bakar Değiştiyse Yenisini Setler
    getAuth().onIdTokenChanged(function (user) {
        if (user) {
            user.getIdToken().then(function (idToken) {
                if (state.auth.token !== idToken) {
                    store.dispatch(setToken(idToken))
                }
            })
        }
    })

    let baseUrl = 'https://api.boostpacket.com'

    const fetchOpt = await fetchOptions(config.method, requestBody, contentType)

    let url = baseUrl + config.path

    if (parameters || parameters === 0) {
        url = url + parameters
    }

    const response = await fetch(url, fetchOpt)

    let responseJson = {}
    if (response.ok) {
        if (response.status === 200 || response.status === 201) {
            try {
                responseJson = await response?.json()
            } catch (e) { }
        }
        const bodyToReturn = {
            status: response.status,
            responseJson: responseJson ?? null,
        }

        return bodyToReturn
    } else {
        return null
    }
}
