import Colors from "@common/Colors"
import styled from "styled-components"

export const Container = styled.div`
display: flex;
flex-direction: column;
height: 100vh;
align-items: center;
background-color: ${Colors.black};
`

export const Logo = styled.img`
margin: 24px 0px;
`

export const MenuItem = styled.div`
border-left: ${props => props.selected ? "2px solid" : "0px"};
border-color: ${Colors.primary};
color: white;
display: flex;
width: 24px;
height: 24px;
padding: 10px 24px;
padding-left: ${props => props.selected ? "22px" : "24px"};
align-items: center;
justify-content: center;
background-color: ${Colors.black};
cursor: pointer;
`
export const LogoutButton = styled.div`
margin-top: auto;
margin-bottom: 20px;
padding:10px 0;
width: 100%;
align-items: center;
text-align: center;
justify-content: center;
cursor: pointer;
`
