import { Table, TableTitle, TableTitleImage, TableTitleText } from "@common/style"
import styled from "styled-components"

export const TableContainer = styled(Table)``
export const ShowTableTitle = styled(TableTitle)``
export const ShowTableTitleImage = styled(TableTitleImage)``
export const ShowTableTitleText = styled(TableTitleText)`
margin-left: unset;
`

export const Container = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  width: 100%;
`
