import apiCall from "@common/api/apiCall"
import { API } from "@common/api/Config"

export default async function getTeamLead({ text, skip = 0, take = 15 }) {

    const parameters = `?skip=${skip ?? 0}&limit=${take ?? 15}&search=${text ?? ""}`

    const response = await apiCall({
        config: API.USER.GET_TEAM_LEAD,
        parameters: parameters,
    })

    if (response) {
        return response
    } else {
        return null
    }

}
