import Form from "../Form/Form"
import Header from "../Header/Header"
import { Container } from "./EditContentStyle"

export default function EditContent({
    setIsItemEdit,
    isItemEditData
}) {

    return (
        <Container>
            <Header
                setIsItemEdit={setIsItemEdit}
            />

            <Form
                isItemEditData={isItemEditData}
            />
        </Container>
    )
}
